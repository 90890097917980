import React, { PureComponent } from "react";
import ReactModal from 'react-modal';
// import { Switch } from "antd";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
// import visaTwo from "./../../images/visa_two.svg";
// import deleteIconRed from "./../../images/delete-icon-red.svg";
import rightArrow from "./../../images/right__arrow.svg";
import { Switch, Menu } from "antd";
import { BackArrowIcon, CloseIcon, DownarrowIcon, RightCaretIcon, StateUpArrowIcon } from "../../components/icons";
// import { SyncIcon } from "../../components/icons";
import _ from "lodash";
import LightBox from "../../utils/lightbox";
import "./styles.scss";
import NumberFormat from "react-number-format";
import { numberFormat } from "../../constants/common";
import DeleteUserModal from "./partials/delete-card"
import EditCustomer from '../EditCustomer/container'
import moment from "moment-timezone";
import InvoiceDetailComponent from "../Billing/partials/invoiceDetail";
import CustomerCredits from './partials/customerCredits'
import CustomerPaymentReceipts from "./partials/customerPaymentReceipt"
import TruckRow from "./partials/TruckRow";
import AddTruckContainer from "../AddTruck/container";
import ProjectOrderDetail from "./partials/ProjectOrderDeatails";
import CreateEditProject from "./partials/createEditProject"
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import AddDriverContainer from "../AddDriver/container";
import CreateStatementComponent from "../CreateStatement/component";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default class CustomerDetailComponent extends PureComponent {
  static propTypes = {
    // PropTypes go here
  };

  constructor(props) {
    const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
    super(props);

    this.state = {
      displayName: "",
      paymentTerms: "",
      paymentMethod: "",
      companyId: "",
      address: "",
      phone: "",
      email: "",
      creditTerms: '',
      dumpRates: {},
      trucks: [],
      drivers: [],
      customer: {},
      editTruckIndex: null,
      images: [],
      isLightBoxOpen: false,
      createStatementPopup : false,
      isDeleteModal: false,
      paymentMethodId: "",
      activeUrl: '',
      pricingTier: {},
      pricingDumpRates: [],
      ismodelOpen: true,
      customerId: "",
      EditCustomerModelOpen: false,
      driverLoader: false,
      invoiceDelivery: "",
      openBalance: 0,
      overDueBalance: 0,
      invoiceData: [],
      isModelOpen: false,
      isPrintRecipt: false,
      modelData: {},
      tab: 'invoice',
      customer_Id:'',
      totalCreditBalance:0,
      customercredits:[],
      customerPaymentReceipt:[],
      customerInvoicesEmpty: false,
      customerPayabliData:{
        StoredMethods:[]
      },
      Invoicelimit: 11,
      currentInvoicePage: 1,
      invoiceskip: 0,
      totalInvoiceItems: 0,
      receiptlimit: 11,
      currentReceiptPage: 1,
      receiptskip: 0,
      totalReceiptItems: 0,
      creditlimit: 11,
      currentTruckPage:1,
      truckSkip:0,
      truckLimit:11,
      currentCreditPage: 1,
      creditskip: 0,
      isloading:false,
      totalCreditItems: 0,
      addIndex:null,
      allContainers:[],
      projectsData:[],
      isprojectDetails:false,
      selectedProjectsDetail:{},
      isCreateAndEditProject:"",
      isProjectModel:false,
      truckAddModal:false,
      driverAddModal:false,
      spinning:false,
      isDriver:false,
      createDriverSidebar:false,
      isLicenseCamera:false,
      tz,
      isLoadingPayment:false,
      driverEditModal: false,
      editDriverData:{},
      sortData: {sortIndex : 1},
      toggleSwtich: false,
      // isModelOpen: false,
      // isPrintRecipt: false,
      // modelData: {},
      // tab: 'invoice',
      // customer_Id:'',
      // totalCreditBalance:0,
      // customercredits:[]
    };
  }
  setAsyncState = newState => new Promise(resolve => this.setState(newState, resolve));
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const id = this.props.customerId;
    // window.location.pathname="customer-detail/C0446/general"
    this.props.getMe();
    const sortData = localStorage.getItem("truckSort");
    if (sortData) {
      await this.setAsyncState({ sortData: JSON.parse(sortData) });
    }
    this.fetchContainers()
    const path = window.location.pathname.split("/")
    if (id === "") {
      this.setState({ customerId: path[2] })
      this.fetchCustomerDetail(path[2]);
    } else {
      this.setState({ customerId: id })
      this.fetchCustomerDetail(id);
    }
    if(this.props.withoutUrl){
      this.setState({activeUrl:"general"})
    }
    if (path[3] === "general" || path[4] === "general" || (path.length > 5 && path[5] === "general")) {
      if ((path.length > 5 && path[5] === "general")) {
        this.setState({ activeUrl: path[5] });
      } else {
        if (path[4] === "general") {
          this.setState({ activeUrl: path[4] })
        } else {
          this.setState({ activeUrl: path[3] });
        }
      }
    }
    if (path[path.length - 1] === "general") {
      this.setState({ activeUrl: "general" });
     await this.fetchCustomerDetail(path[path.length - 2])
    }
    if (path[path.length - 2] === "billing") {
      this.setState({ activeUrl: "billing" });
     await this.fetchCustomerDetail(path[path.length - 3])
      this.fetchInvoiceData(this.state.customer._id)
    }
    if (path[path.length - 1] === "rates") {
      this.setState({ activeUrl: "rates" });
     await this.fetchCustomerDetail(path[path.length - 2])
    }
    if (path[path.length - 1] === "drivers") {
      this.setState({ activeUrl: "drivers" });
     await this.fetchCustomerDetail(path[path.length - 2])
    }
    if (path[path.length - 1] === "trucks") {
      this.setState({ activeUrl: "trucks" });
     await this.fetchCustomerDetail(path[path.length - 2])
    }
    if (path[path.length - 1] === "past-weigh-ins") {
      this.setState({ activeUrl: "past-weigh-ins" });
     await this.fetchCustomerDetail(path[path.length - 2])
    }
    if (path[path.length - 1] === "projects") {
      this.setState({ activeUrl: "projects" });
     await this.fetchCustomerDetail(path[path.length - 2])
     await this.fetchProject();
    }
    if (path[path.length - 1] === "history") {
      this.setState({ activeUrl: "history" });
     await this.fetchLogs(path[path.length - 2])
    }
    if (path[3] === "past-weigh-ins" || (path.length > 5 && path[5] === "past-weigh-ins")) {
      if ((path.length > 5 && path[5] === "past-weigh-ins")) {
        this.setState({ activeUrl: path[5] });
      } else {
        this.setState({ activeUrl: path[3] });
      }
    }
    if (path[3] === "rates") {
      this.setState({ activeUrl: path[3] });
    }
    if (path[3] === "billing") {
      this.setState({ activeUrl: path[3] });
    }
    if (path[3] === "drivers") {
      this.setState({ activeUrl: path[3] });
    }
    if (path[3] === "trucks") {
      this.setState({ activeUrl: path[3] });
    }
    if (path[3] === "history") {
      this.setState({ activeUrl: path[3] });
      this.fetchLogs();
    }
    if(path.includes("payment-receipt")){
      this.fetchCustomerDetail(id);
     this.setState({activeUrl:"billing",tab:"payment-receipt"})
   }

  };

  fetchInvoiceDeliveryData = async (id) => {
    const {
      value: { data },
    } = await this.props.getInvoiceDeliveryByIdData(id);
    this.setState({ invoiceDelivery: data?.name })
  }

  numberWithCommas = (x) => {
    var parts = x.toFixed(2).split(".");
    var num = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  }

  round2decimal = number => {
    return (Math.round(parseFloat(number) * 100) / 100);
  }
  
  fetchLogs = async customerId => {
    const { value } = await this.props.fetchLogs(customerId);
    const logData = value.logData
    this.setState({logData});
  };  
  fetchCustomerDetail = async id => {
  const { truckLimit ,truckSkip} = this.state
  this.setState({driverLoader : true})
  const skip = truckSkip
  const limit = truckLimit
    const {
      value: {
        data: { customer, pastWeighIns, drivers, trucks, pricingTier }
      }
    } = await this.props.fetchCustomer(id,this.state.sortData,this.state.toggleSwtich ? true : false ,skip ,limit);
    let address = customer.address !== "" ? customer.address : "";
    if (address !== "" && customer.city !== "") {
      address += `, ${customer.city}`;
    }
    if (address !== "" && customer.state !== "") {
      address += `, ${customer.state}`;
    }
    if (address !== "" && customer.zip !== "") {
      address += ` ${customer.zip}`;
    }
    if (customer?.invoiceDelivery) {
      this.fetchInvoiceDeliveryData(customer?.invoiceDelivery)
    }

    this.setState({
      customer,
      pastWeighIns,
      drivers,
      pricingTier,
      companyName: customer.displayName,
      paymentTerms: customer.paymentTerms,
      paymentMethod: customer.paymentMethod,
      companyId: customer.companyId,
      address: `${address}`,
      phone: customer.phone,
      email: customer.email,
      dumpRates: pricingTier?.dumpRates,
      customerId: customer.customerId,
      creditTerms: customer?.creditTerms,
      displayName: customer?.displayName,
      driverLoader : false,

    });
    this.fetchInvoiceData(customer._id)
    this.getCustomerReceivePayments(customer._id)
    this.getCustomercredits(customer._id)
    this.fetchLogs(customer?._id);
    this.fetchProject()
  if(pricingTier){
    this.setState({pricingDumpRates:Object.keys(pricingTier?.dumpRates),customer_Id:customer._id})
  }
  if(customer?.payabliCustomer?.customerId){
  this.getCustomerPayment(customer?.payabliCustomer?.customerId)
  }
  if (!trucks || trucks.length === 0) {
    this.setState({
      customerInvoicesEmpty: true,
      currentTruckPage: this.state.currentTruckPage - 1
    });
  }
  else{
    this.setState({
      trucks,
    })
  }
  };

  fetchProject=async()=>{
    this.setState({spinning:true})
    const payload={
      id: this.state.customer?._id,
      searchText:"",
     }
    const {
      value: {
        data
      }
    } = await this.props.fetchProjects(payload);
    this.setState({ projectsData:data,spinning:false});
  }

  getCustomerPayment = async (customerId) => {
    this.setState({isLoadingPayment:true})
    const payload = {
      customerId: customerId
    }
    const { value: { data } } = await this.props.getPayabliCustomerData(payload);
    this.setState({ customerPayabliData: data, isLoadingPayment:false })
  }

  getinvoiceDetailData = async (invoiceId) => {
    const payload = {
      invoiceId: invoiceId
    }
    const { value: { data: { invoiceDetail,projectDetails } } } = await this.props.getInvoiceDetalByInvoiceId(payload);
    let modelData = invoiceDetail[0]
    modelData['customAttachments'] = []
    modelData['showAttachments'] = []
    this.setState({ isModelOpen: true, modelData: modelData, projectDetails : projectDetails})
  }

  closeModal = () => {
    const id = this.props.customerId;
    const path = window.location.pathname.split("/")
    if (id === "") {
      this.setState({ customerId: path[2] })
      this.fetchCustomerDetail(path[2]);
    } else {
      this.setState({ customerId: id })
      this.fetchCustomerDetail(id);
    }
    this.setState({ EditCustomerModelOpen: false })
  }

  closeTruckModal = () => {
    this.fetchCustomerDetail(this.state.customerId);
    this.setState({ truckAddModal: false,editTruckPopup:false })
  }
  closeDriverModal = (driver) => {
    this.fetchCustomerDetail(this.state.customerId);
    this.setState({ driverAddModal: false,drivers:driver,driverEditModal:false })
  }

  fetchInvoiceData = async (_id) => {
    const payload = {
      customerId: _id,
      billingStatus: '',
      skip: this.state.invoiceskip,
      limit: this.state.Invoicelimit
    }
    const { value: { data: { customerInvoice, allData } } } = await this.props.customerInvoicesByCustomerId(payload);
    const { currentInvoicePage } = this.state;
    const { value: { data : {totalBalance}} } = await this.props.customerOpenBalanceInvoicesByCustomerId({customerId: _id})
    if (customerInvoice.length > 0) {
      // let openBalance = 0;
      // let overDueBalance = 0;
      // const currentDate = new Date()
      // let dueDate
      // customerInvoice.map((item) => {
      //   dueDate = new Date(item.dueDate)
      //   if (Number(item.balacnce) > 0) {
      //     openBalance += Number(item?.balacnce)
      //   } if (currentDate > dueDate) {
      //     overDueBalance += item.balacnce
      //   }
      //   return null
      // })
      this.setState({ openBalance: totalBalance ?? 0, overDueBalance: allData?.dueBalance ?? 0, invoiceData: customerInvoice, totalInvoiceItems: allData?.customerInvoice ?? 0  })
    }
    else{
      this.setState({ customerInvoicesEmpty: true ,currentInvoicePage : currentInvoicePage - 1});
    }
  }

  updateinvoiceDetailData = (data) => {
    // const needReviedData = this.state.needReviedData;
    // needReviedData[data.index] = data;
    // this.setState({ needReviedData: needReviedData });
  };

  getKeyName(key) {
    switch (key) {
      case "defaultOption":
        return "";
      case "pricePerTonLight":
        return "Price Per Ton (light)";
      case "pricePerTonHeavy":
        return "Price Per Ton (heavy)";
      case "pricePerTonMixedCD":
        return "Price Per Ton Mixed C&D";
      case "pricePerTonWood":
        return "Price Per Ton Wood";
      case "pricePerTonMetal":
        return "Price Per Ton Metal";
      case "pricePerTonRoofing":
        return "Price Per Ton Roofing";
      case "pricePerTonMasonry":
        return "Price Per Ton Masonry";
      case "pricePerTonBrick":
        return "Price Per Ton Brick";
      case "pricePerTonDirt":
        return "Price Per Ton Dirt";
      case "pricePerTonConcrete":
        return "Price Per Ton Concrete";
      case "pricePerTonCD":
        return "Price Per Ton C&D";
      case "pricePerTonLowRecovery":
        return "Price Per Ton Low Recovery";
      case "pricePerTonOpenContainer":
        return "Price Per Ton Open Container";
      case "pricePerTonClosedContainer":
        return "Price Per Ton Closed Container";
      case "pricePerTonTrailer":
        return "Price Per Ton Trailer";
      case "pricePerTonAsbestos":
        return "Price Per Ton Asbestos";
      case "pricePerYardLight":
        return "Price Per Yard (light)";
      case "pricePerYardHeavy":
        return "Price Per Yard (heavy)";
      case "pricePerYardMixedCD":
        return "Price Per Yard Mixed C&D";
      case "pricePerYardWood":
        return "Price Per Yard Wood";
      case "pricePerYardMetal":
        return "Price Per Yard Metal";
      case "pricePerYardRoofing":
        return "Price Per Yard Roofing";
      case "pricePerYardMasonry":
        return "Price Per Yard Masonry";
      case "pricePerYardBrick":
        return "Price Per Yard Brick";
      case "pricePerYardDirt":
        return "Price Per Yard Dirt";
      case "pricePerYardConcrete":
        return "Price Per Yard Concrete";
      case "pricePerYardCD":
        return "Price Per Yard C&D";
      case "pricePerYardLowRecovery":
        return "Price Per Yard Low Recovery";
      case "pricePerYardOpenContainer":
        return "Price Per Yard Open Container";
      case "pricePerYardClosedContainer":
        return "Price Per Yard Closed Container";
      case "pricePerYardTrailer":
        return "Price Per Yard Trailer";
      case "pricePerYardAsbestos":
        return "Price Per Yard Asbestos";
      default:
        // const type = key.includes('pricePerYard') ? 'pricePerYard' : 'pricePerTon';
        const displayTitle = _.startCase(key)
        return displayTitle;
    }
  }

  onDriverStatusChange = async driver => {
    const id = this.state.customerId
    const { updateDriver } = this.props;
    const payload = {
      _id: driver._id,
      status: driver.status === 1 ? 3 : 1
    };

    const {
      value: { status }
    } = await updateDriver(payload);
    if (status) {
      this.fetchCustomerDetail(id);
    }
  };

  fetchContainers = async()=>{
    const { fetchContainers } = this.props;
    const {
      value: {
        data: { containers }
      }
    } = await fetchContainers();
    const allContainers = containers.map((item)=>({...item,defaultWeight:""}))
    this.setState({allContainers:allContainers ?? []})
  }

  getCustomercredits = async (customerId) => {
    // getCustomercreditsForCustomers={this.props.getCustomercreditsForCustomers}
    const payload = {
        customerId: customerId,
        limit: this.state.creditlimit,
        skip: this.state.creditskip
    }
    const {
        value: {
            data: { customercredits, totalCount,totalCreditAmount }
        }
    } = await this.props.getCustomercreditsForCustomers(payload);
    const { currentCreditPage } = this.state;
    if(customercredits.length>0){
        this.setState({ customercredits: customercredits, totalCreditBalance: Number(totalCreditAmount[0]?.totalCreditAmount), totalCreditItems: totalCount  })
    }
    else{
      this.setState({ customerInvoicesEmpty: true ,currentCreditPage : currentCreditPage - 1});
    }
}

getCustomerReceivePayments = async (customerId) => {
  // getCustomercreditsForCustomers={this.props.getCustomercreditsForCustomers}
  const payload = {
      customerId: customerId,
      limit: this.state.receiptlimit,
      skip: this.state.receiptskip 
  }
  const {
      value: {
          data: { paymentInvoices, totalCount }
      }
  } = await this.props.getReceivePaymentForCustomers(payload);
  const { currentReceiptPage } = this.state;
  if (paymentInvoices?.length > 0) {
      this.setState({ customerPaymentReceipt: paymentInvoices, totalReceiptItems: totalCount})
  }
  else{
    this.setState({ customerInvoicesEmpty: true ,currentReceiptPage : currentReceiptPage - 1});
  }
}
  handleTruckWeightChange(e, i) {
    const trucks = [...this.state.trucks];
    const val = e.target.value === "" ? 0 : e.target.value.replace(/[^\d]/g, "");
    trucks[i].defaultOutboundWeight = val;
    this.setState({ trucks });
  }

  deletePaymentMethod({ _id }) {
    this.setState({ paymentMethodId: _id, isDeleteModal: true });
  }

  menu({ _id }) {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => this.deletePaymentMethod({ _id })}>
          Delete
        </Menu.Item>
      </Menu>
    );
  }

  reArrangOrdersData = (orderData) => {
    let order = orderData
    let reArrangOrders = []
    if (order.length > 0) {
      order.map((item) => {
        if (item?.orderCreationType === "createCharge") {
          if ((item?.orderBreakDownItems?.isminimumPrice)) {
            let obj = {
              fixedItems: [{
                fixedItem: "Dumping services",
                unitPrice: item?.orderBreakDownItems?.minimumPrice,
                units: 1,
                totalamout: item?.orderBreakDownItems?.totalAmountDue,
              }],
              ticketNumber: item?.ticketNumber,
              orderCreationType: item?.orderCreationType
            }
            reArrangOrders.push(obj)
            return null
          } else {
            if (item?.orderBreakDownItems?.isCapOutPrice) {

              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType
              }
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  const fixedItemName = _.startCase(fixedItems?.fixedItem)
                  if (fixedItemName.includes("Yard")) {
                    if (Number(fixedItems?.unitPrice) > Number(item?.capoutPrice)) {
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: item?.orderBreakDownItems?.capoutPrice,
                        units: fixedItems.units,
                        totalamout: item?.orderBreakDownItems?.capoutPrice * fixedItems.units,
                      }
                      obj.fixedItems.push(fixedItemObject)
                    }
                  } else {
                    let fixedItemObject = {
                      fixedItem: fixedItems.fixedItem,
                      unitPrice: fixedItems?.unitPrice,
                      units: fixedItems.units,
                      totalamout: fixedItems?.unitPrice * fixedItems.units,
                    }
                    obj.fixedItems.push(fixedItemObject)
                  }
                  return null
                })
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map((chargeByWeightFixedItems) => {
                  let chargeByWeightItemObject = {
                    fixedItem: chargeByWeightFixedItems?.fixedItem,
                    unitPrice: chargeByWeightFixedItems?.unitPrice,
                    units: chargeByWeightFixedItems?.units,
                    totalamout: chargeByWeightFixedItems?.unitPrice * chargeByWeightFixedItems?.units,
                  }

                  obj.fixedItems.push(chargeByWeightItemObject)
                  return null
                })
              }
              reArrangOrders.push(obj)
            } else {
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType
              }
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  let fixedItemObject = {
                    fixedItem: fixedItems?.fixedItem,
                    unitPrice: fixedItems?.unitPrice,
                    units: fixedItems?.units,
                    totalamout: fixedItems?.unitPrice * fixedItems?.units,
                  }
                  obj.fixedItems.push(fixedItemObject)
                  return null
                })
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map((chargeByWeightItems) => {
                  let chargeByWeightItemsObject = {
                    fixedItem: chargeByWeightItems?.fixedItem,
                    unitPrice: chargeByWeightItems?.unitPrice,
                    units: chargeByWeightItems?.units,
                    totalamout: chargeByWeightItems?.unitPrice * chargeByWeightItems?.units,
                  }
                  obj.fixedItems.push(chargeByWeightItemsObject)
                  return null
                })
              }
              reArrangOrders.push(obj)
            }
          }
        } else {
          let obj = {
            inboundWeight: item?.inboundWeight,
            outboundWeight: item?.outboundWeight,
            dumpRateType: item?.dumpRateType,
            dumpRate: item?.dumpRate,
            ticketNumber: item?.ticketNumber,
            netWeight: item?.netWeight,
            yardage: item?.yardage,
            tonnage: item?.tonnage,
            totalAmount: item?.totalAmount
          }
          reArrangOrders.push(obj)
        }
        return null
      })
    }
    // this.setState({reArrangOrders:reArrangOrders,reRender: !this.state.reRender})
    return reArrangOrders
  }


  async saveTruckDetails(truck) {
    const { id } = this.props.match.params;
    const { updateTruck } = this.props;
    const payload = {
      _id: truck._id,
      defaultOutboundWeight: truck.defaultOutboundWeight,
      // containers:["5","30","40"]
    };
    const {
      value: { status }
    } = await updateTruck(payload);
    if (status) {
      this.setState({ editTruckIndex: null });
      this.fetchCustomerDetail(id);
    }
  }

  onTruckStatusChange = async truck => {
    const id = this.state.customerId;
    const { updateTruck } = this.props;
    const payload = {
      _id: truck._id,
      status: truck.status === 1 ? 3 : 1
    };

    const {
      value: { status }
    } = await updateTruck(payload);
    if (status) {
      this.fetchCustomerDetail(id);
    }
  };

  invoiceDetailCloseModel = () => {
    this.setState({ isModelOpen: false });
  };

  onTabChange = (value) => {
    this.setState({ tab: value }, () => {
      this.forceUpdate()
    })
    this.props.changeUrl(`/customer-detail/${this.state.customerId}/billing/${value}`)
  }

  onCreditChangePage = (page) => {
    const { currentCreditPage, creditlimit } = this.state;
    let nextPage;

    if (page === 'next') {
        nextPage = currentCreditPage + 1;
    } else if (page === 'prev' && currentCreditPage > 1) {
        nextPage = currentCreditPage - 1;
    } else {
        return;
    }

    const newSkip = (nextPage - 1) * (creditlimit-1);
    this.setState(
      { currentCreditPage: nextPage, creditskip: newSkip ,customerInvoicesEmpty: false },
      () => {
        this.getCustomercredits(this.state.customer._id)
      }
    );
  };

  onReceiptChangePage = (page) => {
    const { currentReceiptPage, receiptlimit } = this.state;
    let nextPage;
    if (page === 'next') {
        nextPage = currentReceiptPage + 1;
    } else if (page === 'prev' && currentReceiptPage > 1) {
        nextPage = currentReceiptPage - 1;
    } else {
        return;
    }
    const newSkip = (nextPage - 1) * (receiptlimit-1);
    this.setState(
        { currentReceiptPage: nextPage, receiptskip: newSkip, customerInvoicesEmpty: false }, // Corrected variable name
        () => {
            this.getCustomerReceivePayments(this.state.customer._id);
        }
    );
};

  onChangePage = (direction) => {
    const { currentInvoicePage, Invoicelimit } = this.state;
    let nextPage;
  
    if (direction === 'next') {
      nextPage = currentInvoicePage + 1;
    } else if (direction === 'prev' && currentInvoicePage > 1) {
      nextPage = currentInvoicePage - 1;
    } else {
      return; 
    }
  
    const newSkip = (nextPage - 1) * (Invoicelimit-1);
    this.setState(
      { currentInvoicePage: nextPage, invoiceskip: newSkip , customerInvoicesEmpty: false },
      () => {
        this.fetchInvoiceData(this.state.customer._id);
      }
    );
  };
  onTruckChangePage = direction => {
    const { currentTruckPage, truckLimit } = this.state;
    let nextPage;
  
    if (direction === 'next') {
      nextPage = currentTruckPage + 1;
    } else if (direction === 'prev' && currentTruckPage > 1) {
      nextPage = currentTruckPage - 1;
    } else {
      return; 
    }
    const newSkip = (nextPage - 1) * (truckLimit-1);
    this.setState({ currentTruckPage: nextPage, truckSkip: newSkip , customerInvoicesEmpty: false , isloading: true}, () => {
      this.fetchCustomerDetail(this.state.customerId);
      this.setState({ isloading: false });
    });
  };
  

assignTruckWeight = (truck) => {
  this.setState(truck)
}
async setOrder(field) {
  const { sortData ,customerId} = this.state;
  const sort = { [field]: sortData[field] === 1 ? -1 : 1 };
  localStorage.setItem("truckSort", JSON.stringify(sort));
  const {
    value: {
      data: {  trucks }
    }
  } = await this.props.fetchCustomer(customerId,sort,this.state.toggleSwtich ? true : false);
  
    this.setState({ trucks ,sortData: sort });

}
handleDrag = async (e, fromIndex, toIndex) => {
  const updatedTrucks = [...this.state.trucks];
  const removedTruck = updatedTrucks.splice(fromIndex, 1)[0];
  updatedTrucks.splice(toIndex, 0, removedTruck);
  updatedTrucks.forEach((truck, index) => {
    truck.sortIndex = index;
    truck.customerId = this.state.customer_Id;
  });
  const payload = updatedTrucks
  localStorage.setItem("truckSort", JSON.stringify({sortIndex : 1}));
  const {
    value: { status ,data}
  } = await this.props.updateTruckWithIndex(payload);
  if (status) {
    this.setState({
      trucks: data,
    });
  }
 
};

editTruck = (truckData)=>{
  this.setState({editTruckPopup:true,editTruckData:truckData})
}
handleEditDriver = (DriverData)=>{
  this.setState({driverEditModal:true,editDriverData:DriverData})
}
handleActiveTrucks = async (checked) => {
  if (checked) {
    this.setState({ toggleSwtich: true },()=>this.fetchCustomerDetail(this.props.customerId));
  } else {
    this.setState({ toggleSwtich: false },()=>this.fetchCustomerDetail(this.props.customerId));
  }
}

  render() {

    const {
      displayName,
      paymentTerms,
      address,
      phone,
      email,
      dumpRates,
      customerId,
      pastWeighIns,
      drivers,
      trucks,
      // editTruckIndex,
      customer,
      activeUrl,
      pricingTier,
      tab,
      sortData
    } = this.state;
    const _address = address !== "" ? address.split(",") : [];
    return (
      <div>
        <LightBox
          isOpen={this.state.isLightBoxOpen}
          images={this.state.images}
          onClose={() => this.setState({ isLightBoxOpen: false })}
          className="adadad"
        />
        <ReactModal
          isOpen={this.state.ismodelOpen}
          onRequestClose={() => { this.props.closeModel();document.body.classList.remove("ReactModal__Body--open"); this.setState({ ismodelOpen: false }) }}
          className="top__content-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered atoz__construction-modal">
            <div className="react-modal-header">
              <h5 className="react-modal-title">{displayName ? displayName : customer?.customerName} </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={() => { this.setState({ ismodelOpen: false });document.body.classList.remove("ReactModal__Body--open"); this.props.closeModel() }}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="react-modal-body">
              <section className="tab__section">
                <ul className="list tab__list">
                  <li onClick={() => {
                    this.props.changeUrl(
                      `/customer-detail/${this.state.customerId}/general`); this.setState({ activeUrl: "general" })
                  }} className={activeUrl === "general" ? "tab__items active" : "tab__items"}>
                    General
                  </li>
                  <li onClick={() => {
                    this.fetchInvoiceData(customer?._id); this.props.changeUrl(
                      `/customer-detail/${this.state.customerId}/billing/invoice`); this.setState({ activeUrl: "billing" })
                  }} className={activeUrl === "billing" ? "tab__items active" : "tab__items"}>
                    Billing
                  </li>
                  <li onClick={() => {
                    this.props.changeUrl(
                      `/customer-detail/${this.state.customerId}/projects`); this.setState({ activeUrl: "projects" })
                  }} className={activeUrl === "projects" ? "tab__items active" : "tab__items"}>
                    Projects
                  </li>
                  <li onClick={() => {
                    this.props.changeUrl(
                      `/customer-detail/${this.state.customerId}/past-weigh-ins`); this.setState({ activeUrl: "past-weigh-ins" })
                  }} className={activeUrl === "past-weigh-ins" ? "tab__items active" : "tab__items"}>
                    Past Weigh-Ins
                  </li>
                  <li onClick={() => {
                    this.props.changeUrl(
                      `/customer-detail/${this.state.customerId}/rates`); this.setState({ activeUrl: "rates" })
                  }} className={activeUrl === "rates" ? "tab__items active" : "tab__items"}>
                    Rates
                  </li>
                  <li onClick={() => {
                    this.props.changeUrl(
                      `/customer-detail/${this.state.customerId}/drivers` ); this.setState({ activeUrl: "drivers" })
                  }} className={activeUrl === "drivers" ? "tab__items active" : "tab__items"}>
                    Drivers
                  </li>
                  <li onClick={() => {
                    this.props.changeUrl(
                      `/customer-detail/${this.state.customerId}/trucks`); this.setState({ activeUrl: "trucks" })
                  }} className={activeUrl === "trucks" ? "tab__items active" : "tab__items"}>
                    Trucks
                  </li>
                  <li onClick={() => {
                    this.props.changeUrl(
                      `/customer-detail/${this.state.customerId}/payment-methods`); this.setState({ activeUrl: "payment-methods" })
                      this.getCustomerPayment(this.state.customer?.payabliCustomer?.customerId)
                  }} className={activeUrl === "payment-methods" ? "tab__items active" : "tab__items"}>
                    Payment Methods
                  </li>
                  <li onClick={() => {
                    this.fetchLogs(customer?._id); this.props.changeUrl(
                      `/customer-detail/${this.state.customerId}/history`); this.setState({ activeUrl: "history" })
                  }} className={activeUrl === "history" ? "tab__items active" : "tab__items"}>
                    History
                  </li>
                </ul>
                {activeUrl === "general" && <>
                  <div className="tab__body general__body">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title">
                          Company Information
                        </h5>
                        <button onClick={() => {
                          this.setState({ EditCustomerModelOpen: true })
                        }
                          // this.props.history.push({
                          //   pathname: "/edit-customer",
                          //   state: {
                          //     id: _.get(this.props, "match.params.id", "")
                          //   }
                          // })

                        } className="btn btn-dark">Edit</button>
                      </div>
                      <div className="card-body">
                        <ul className="card__list">
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Company Name</h6>
                              <p className="sub__title">{customer.companyName ? customer.companyName : "N/A"}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Display Name</h6>
                              <p className="sub__title">{customer?.displayName ? customer?.displayName : "N/A"} </p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Pricing Tier </h6>
                              <p className="sub__title">{pricingTier?.pricingTier ? pricingTier?.pricingTier : "N/A"}  </p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Company ID </h6>
                              <p className="sub__title">{customerId ? customerId : "N/A"}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Fax Number</h6>
                              <p className="sub__title">{customer.fax ? customer.fax : "N/A"}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Payment Terms</h6>
                              <p className="sub__title">{_.toUpper(paymentTerms)} </p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Company Address</h6>
                              <p className="sub__title">{_address.length > 0 ? (
                                <p className="sub__title">
                                  {_address[0]}
                                  <br />
                                  {_address[1]}, {_address[2]} {_address[3]}
                                </p>
                              ) : (
                                "N/A"
                              )}</p>
                            </div>
                          </li>
                          {email && (
                            <li>
                              <div className="company__driver-title">
                                <h6 className="title">Company Email</h6>
                                <p className="sub__title">{email ? email : "N/A"}</p>
                              </div>
                            </li>
                          )}
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Payment Type</h6>
                              <p className="sub__title">{customer?.paymentType ? customer?.paymentType === "credit-card" ? customer?.paymentType.replace("credit-card", "Credit card") : customer?.paymentType.charAt(0).toUpperCase() + customer?.paymentType.slice(1) : "N/A"}</p>
                            </div>
                          </li>
                          <li>&nbsp;</li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Company Phone Number</h6>

                              <p className="sub__title">{
                                phone ?
                                  <NumberFormat
                                    format="(###) ###-####"
                                    allowEmptyFormatting
                                    mask=""
                                    displayType={"text"}
                                    value={phone}
                                  /> : "N/A"}</p>
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Invoice Frequency</h6>
                              <p className="sub__title">{customer?.invoiceFrequency ? customer?.invoiceFrequency.charAt(0).toUpperCase() + customer?.invoiceFrequency.slice(1) : "N/A"}</p>
                            </div>
                          </li>
                          <li>&nbsp;</li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Billing Contact Name </h6>
                              <p className="sub__title">{customer?.billingContactName ? customer?.billingContactName : "N/A"}</p>
                              {customer?.secondaryBillingContacts?.map((v,i)=>(
                                <p key={i} className="sub__title">{v?.billingContactName}
                              </p>
                              ))}
                            </div>
                          </li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Payment Methods </h6>
                              { this.state.customerPayabliData?.StoredMethods?.length > 0 && this.state.customerPayabliData?.StoredMethods.map((item,index)=>{
                              return (
                                <>
                                  <p className="sub__title">
                                    {`${item.Descriptor} ${ item.MaskedAccount}`}
                                  </p>
                                  <p className="text-green">
                                    Active
                                  </p>
                                </>
                              );

                            })  }
                            {
                              this.state?.customer?.paymentmethods?.map(card => {
                                return (
                                  <>
                                    <p className="sub__title">
                                      {`${card?.details?.brand ?? ""} **** ${ card?.details?.last4digits}`}
                                    </p>
                                  </>
                                );
                              })
                            }
                            {this.state.customerPayabliData?.StoredMethods?.length <= 0 &&  this.state?.customer?.paymentmethods?.length <= 0 &&
                              <p className="sub__title">
                                N/A
                              </p>}
                            </div>
                          </li>
                          <li>&nbsp;</li>
                          <li>
                            <div className="company__driver-title">
                              <h6 className="title">Billing Email </h6>
                              <p className="sub__title">{customer?.billingEmailAddress ? customer?.billingEmailAddress : "N/A"}
                              </p>
                              {customer?.secondaryBillingContacts?.map((v,i)=>(
                                <p key={i} className="sub__title">{v?.billingEmailAddress}
                              </p>
                              ))}
                            </div>
                          </li>
                          {customer?.paymentTerms === "account" &&
                            <>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Billing Terms</h6>
                                  <p className="sub__title"> NET {customer?.creditTerms ? customer?.creditTerms : "N/A"}
                                  </p>
                                </div>
                              </li>
                              <li>&nbsp;</li>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">Invoice Delivery</h6>
                                  <p className="sub__title">{this.state.invoiceDelivery ? this.state.invoiceDelivery : "N/A"}
                                  </p>
                                </div>
                              </li>
                            </>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
                }
                {activeUrl === 'past-weigh-ins' &&
                  <div className="tab__body past__weighins-body">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title">
                          Past Weigh-Ins
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table custom-table">
                            <thead>
                              <tr>
                                <th>Ticket Number</th>
                                <th>Inbound Weight</th>
                                <th>Outbound Weight</th>
                                <th>Net Weight</th>
                                <th>Tonnage</th>
                                <th colSpan={2}>Total Order Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pastWeighIns?.length > 0 ? pastWeighIns.map(weighin => {
                                return (
                                  <><tr>
                                    <td>{weighin.ticketNumber}</td>
                                    <td> <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"text"}
                                      value={weighin.inboundWeight}
                                    />{" "}
                                      lbs</td>
                                    <td> <NumberFormat
                                      thousandSeparator={true}
                                      displayType={"text"}
                                      value={weighin.outboundWeight}
                                    />{" "}
                                      lbs</td>
                                    <td><NumberFormat
                                      thousandSeparator={true}
                                      displayType={"text"}
                                      value={weighin.netWeight}
                                    />{" "}
                                      lbs</td>
                                    <td> {weighin.tonnage} tons{" "}
                                      <NumberFormat
                                        thousandSeparator={true}
                                        displayType={"text"}
                                        value={weighin.netWeight}
                                      />{" "}
                                      lb /2,000</td>
                                    <td> <span className="amount">{numberFormat(weighin.totalAmount)} </span></td>
                                    <td className="underline">

                                      <span onClick={() => {
                                        this.props.changeUrl(`/order-details/${weighin.ticketNumber}`)
                                      }} className="strong__text">View Details <img className="right__arrow" src={rightArrow} alt="" /></span>
                                    </td>
                                  </tr>
                                  </>
                                )
                              }) : (
                                // <NoRecords title="No  Past Weigh-Ins" />
                                <tr>
                                  <td colSpan={10}>
                                    <div className="empty__section">
                                      <div className="empty__section-inner">
                                        <p className="empty__text">No Past Weigh-Ins</p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                 {activeUrl === 'projects' &&
                  <div className="tab__body project-body">
                    { !this.state.isprojectDetails ?
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title">
                          Projects
                        </h5>
                        <button onClick={()=>{this.setState({isCreateAndEditProject:"create",isProjectModel:true})}} className="btn btn-dark">
                          Create Project
                        </button>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table custom-table customer-detail-project-table">
                          <thead>
                              <tr>
                                <th>Project Name</th>
                                {/* <th></th> */}
                                <th>Number Of Project</th>
                                {/* <th></th> */}
                                <th>Project Status</th>
                              </tr>
                            </thead>
                            <tbody>
                            { this.state.spinning ?
                                <tr>
                                  <td colSpan={10}>
                                    <div className="empty__section">
                                      <div className="empty__section-inner">
                                      <Spin indicator={antIcon}/>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                  :
                              this.state.projectsData?.length > 0 ? 
                             this.state.projectsData.map((project) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{project.projectName}</td>
                                      {/* <td></td> */}
                                      <td>{project.order.length}</td>
                                      {/* <td></td> */}
                                      <td>{project.projectStatus === "active" ? "Active" : "In Active"}</td>
                                      <td className="col-2 text-right">
                                        <button 
                                          onClick={() => {
                                            this.setState({isprojectDetails:true,selectedProjectsDetail:project })
                                          }} className="underline blackText TransparentBtn text_font mr-5">
                                          View Projects 
                                        </button>
                                        <button className="underline blackText TransparentBtn text_font mr-1" onClick={()=>{this.setState({isCreateAndEditProject:"edit",isProjectModel:true,selectedProjectsDetail:project})}} >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  </>
                                )
                               })
                                : (
                                <tr>
                                  <td colSpan={10}>
                                    <div className="empty__section">
                                      <div className="empty__section-inner">
                                        <p className="empty__text">No Projects</p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    :
                    (
                    <>
                      <div className="card">
                        <div className="card-header">
                          <button onClick={()=>{this.setState({isprojectDetails:false})}} className="btn btn-link btn-back">
                            <BackArrowIcon />
                            {this.state.selectedProjectsDetail?.projectName}
                          </button>
                        </div>
                        <div className="card-body">
                          {
                            <ProjectOrderDetail 
                            projectdetails={this.state.selectedProjectsDetail}
                            customer={this.state.customer}
                            {...this.props}
                            />
                          }
                        </div>
                      </div>
                    </>
                    ) }
                    {this.state.isCreateAndEditProject !=="" && 
                    <>
                    <CreateEditProject 
                       isProjectModel={this.state.isProjectModel}
                       customerData={this.state.customer}
                       isCreateAndEditProject={this.state.isCreateAndEditProject}
                       closeModal={()=>{this.setState({isProjectModel:false,isCreateAndEditProject:""});this.fetchProject()}}
                       projectData={this.state.selectedProjectsDetail}
                       {...this.props}
                    />
                    </>
                    }
                  </div>
                }
                {activeUrl === "billing" &&
                  <div className="tab__body billing-body">
                    <div className="card-body">
                      <ul className="card__list">
                        <li>
                          <div className="company__driver-title">
                            <h6 className="title">Company Name</h6>
                            <p className="sub__title">{customer.displayName ? customer.displayName : "N/A"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="company__driver-title">
                            <h6 className="title">Company Email</h6>
                            <p className="sub__title">{customer?.email ? customer?.email : "N/A"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="company__driver-title">
                            <h6 className="title">Payment Terms</h6>
                            <p className="sub__title">{_.toUpper(paymentTerms)}</p>
                          </div>
                        </li>
                        <li>
                          <div className="company__driver-title">
                            <h6 className="title">Invoice Frequency</h6>
                            <p className="sub__title">{customer?.invoiceFrequency ? customer?.invoiceFrequency.charAt(0).toUpperCase() + customer?.invoiceFrequency.slice(1) : "N/A"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="company__driver-title">
                            <h6 className="title">Company Address</h6>
                            <p className="sub__title">{_address.length > 0 ? (
                              <p className="sub__title">
                                {_address[0]}
                                <br />
                                {_address[1]}, {_address[2]} {_address[3]}
                              </p>
                            ) : (
                              "N/A"
                            )}</p>
                          </div>
                        </li>
                        <li>
                          <div className="company__driver-title">
                            <h6 className="title">Company Phone Number</h6>
                            <p className="sub__title">{
                              phone ?
                                <NumberFormat
                                  format="(###) ###-####"
                                  allowEmptyFormatting
                                  mask=""
                                  displayType={"text"}
                                  value={phone}
                                /> : "N/A"}</p>
                          </div>
                        </li>
                        <li>
                          <div className="company__driver-title">
                            <h6 className="title">Payment Method</h6>
                            <p className="sub__title">{customer?.paymentType ? customer?.paymentType === "credit-card" ? customer?.paymentType.replace("credit-card", "Credit card") : customer?.paymentType.charAt(0).toUpperCase() + customer?.paymentType.slice(1) : "N/A"}</p>
                            
                          </div>
                        </li>
                      </ul>
                      <div className="open__overdue-balance">
                        <ul className="open__overdue-list">
                          <li className="items open__balance">
                            <h4 className="sub-title">Open Balance</h4>
                            <h2 className="title">${this.numberWithCommas(Number(parseFloat(this.state.openBalance).toFixed(2)))}</h2>
                        {/* {
                          this.state.openBalance > 0 && <span className="statement-text" onClick={()=> this.props.history.push(`/create-statement/${customer?.customerId}`)}>Create Statement</span>
                        } */}
                        {
                          this.state.openBalance > 0 && <span className="statement-text" onClick={()=> this.setState({createStatementPopup : true})}>Create Statement</span>
                        }
                          </li>
                          <li className="items overdue__balance">
                            <h4 className="sub-title">Overdue</h4>
                            <h2 className="title">${this.numberWithCommas(Number(parseFloat(this.state.overDueBalance).toFixed(2)))}</h2>
                          </li>
                          <li className="items credit__balance">
                            <h4 className="sub-title">Credit</h4>
                            <h2 className="title">${ this.state.totalCreditBalance ? this.numberWithCommas(Number(this.state.totalCreditBalance)): "0"}</h2>
                          </li>
                        </ul>
                      </div>
                      <div className="billing__invoice-table">
                        {/* <h2 className="title">Invoices</h2> */}
                        <ul className="list tab__list">
                          <li onClick={() => this.onTabChange('invoice')} className={tab === "invoice" ? "tab__items active" : "tab__items"}>
                            Invoices
                          </li>
                          <li onClick={() => this.onTabChange('payment-receipt')} className={tab === "payment-receipt" ? "tab__items active" : "tab__items"}>
                            Payment Receipts
                          </li>
                          <li onClick={() => this.onTabChange('credit-memo')} className={tab === "credit-memo" ? "tab__items active" : "tab__items"}>
                            Credits
                          </li>
                        </ul>
                        {tab === "invoice" &&
                          <div className="show-data active">
                            <div className="table-responsive">
                              <table className="table custom-table">
                                <thead>
                                  <tr>
                                    <th className="first-child">Invoice #</th>
                                    {/* <th className="text-center">Quickbooks Sync</th> */}
                                    <th>Invoice Date</th>
                                    <th>Due Date</th>
                                    <th>Balance</th>
                                    <th>Total Order Amount</th>
                                    <th className="last-child">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.invoiceData.length > 0 ? this.state.invoiceData.map((item) =>
                                    <tr>
                                      <td onClick={() => { this.getinvoiceDetailData(item?.invoiceId) }} className="first-child underline">{item?.invoiceId}</td>
                                      {/* <td className="text-center"><SyncIcon className="icon sync__icon" /></td> */}
                                      <td>{moment(item?.invoiceDate)?.tz(this.state?.tz)?.format('l') + " " + moment(item?.invoiceDate)?.tz(this.state?.tz)?.format('LT')}</td>
                                      <td>{moment(item?.dueDate)?.tz(this.state?.tz)?.format('l')}</td>
                                      <td>${this.numberWithCommas(Number(parseFloat(item?.balacnce).toFixed(2)))}</td>
                                      <td>${this.numberWithCommas(Number(parseFloat(item?.total).toFixed(2)))}</td>
                                      {/* <td className="last-child"><button className="btn btn-for-cc">Open</button></td> */}
                                      <td>
                                        {/* <button className={item?.balacnce === 0 ? "btn btn-for-code btn-green " : "btn btn-for-cc"}> {item?.balacnce === 0 ? "Paid" : "open"}</button> */}
                                        {(item?.paymentStatus === "Open" || item?.paymentStatus === "open") &&
                                          <button className="btn btn-for-cc">Open</button>
                                        }
                                        {(item?.paymentStatus === "Overdue" || item?.paymentStatus === "overdue") &&
                                          <button className="btn red-btn-status">Overdue</button>
                                        }
                                        {(item?.paymentStatus === "Applied" || item?.paymentStatus === "applied") &&
                                          <button className="btn gray-btn-status">Applied</button>
                                        }
                                        {(item?.paymentStatus === "Paid" || item?.paymentStatus === "paid") &&
                                          <button className="btn btn-for-code btn-green">Paid</button>}
                                        {(item?.paymentStatus === "Voided" || item?.paymentStatus === "voided") &&
                                          <button className="btn btn-for-code btn-voided">Voided</button>}
                                      </td>
                                    </tr>
                                  ):
                                  <tr>
                                        <td colSpan={10}>
                                            <div className="empty__section">
                                                <div className="empty__section-inner">
                                                    <p className="empty__text">No Invoices</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                  }
                                  {/* <tr>
                                  <td className="first-child underline">39282</td>
                                  <td className="text-center"><SyncIcon className="icon sync__icon" /></td>
                                  <td>06/15/2020 7:10 am</td>
                                  <td>11/18/2022</td>
                                  <td>$39,292.00</td>
                                  <td>$39,292.00</td>
                                  <td className="last-child"><button className="btn btn-red btn-status">Overdue</button></td>
                                </tr>
                                <tr>
                                  <td className="first-child underline">25100</td>
                                  <td className="text-center"><SyncIcon className="icon sync__icon" /></td>
                                  <td>06/01/2020 7:10 am</td>
                                  <td>11/18/2022</td>
                                  <td>$3.20</td>
                                  <td>$3.20</td>
                                  <td className="last-child"><button className="btn btn-green btn-status">Paid</button></td>
                                </tr> */}

                                </tbody>
                              </table>
                              
                            </div>
                            <div className="d-flex align-items-center text-center justify-content-center">
                              <button className="btn btn-dark btn-xs mr-2 btn-previous" onClick={() => this.onChangePage('prev')} disabled={this.state.currentInvoicePage <= 1 || this.state.invoiceData.length === 0}><RightCaretIcon/>Previous</button>
                              <button className="btn btn-dark btn-xs btn-next" onClick={() => this.onChangePage('next')} disabled={!(this.state.invoiceData.length > 10 )}>Next <RightCaretIcon/></button>                            </div>

                          </div>}
                          {tab === "credit-memo" && 
                          <> < CustomerCredits 
                          onCreditChangePage={this.onCreditChangePage}
                          creditlimit={this.state.creditlimit}
                          creditskip={this.state.creditskip}
                          currentCreditPage={this.state.currentCreditPage}
                          customerInvoicesEmpty={this.state.customerInvoicesEmpty}
                          customerId = {this.state.customer._id}
                          getCustomerReceivePayments={this.getCustomerReceivePayments}
                          syncPostedOrder={this.props.syncPostedOrder}
                          customercredits={this.state.customercredits}
                          getinvoiceDetailData={this.getinvoiceDetailData}
                          /></>
                       }
                        {tab === "payment-receipt" && 
                          <> <CustomerPaymentReceipts
                            onReceiptChangePage={this.onReceiptChangePage}
                            receiptlimit={this.state.receiptlimit}
                            receiptskip={this.state.receiptskip}
                            currentReceiptPage={this.state.currentReceiptPage}
                            customerInvoicesEmpty={this.state.customerInvoicesEmpty}
                            customerId = {this.state.customer._id}
                            getCustomerReceivePayments={this.getCustomerReceivePayments}
                            syncPostedOrder={this.props.syncPostedOrder}
                            customerPaymentReceipt={this.state.customerPaymentReceipt}
                            getinvoiceDetailData={this.getinvoiceDetailData}
                            getReceivePaymentForPdf={this.props.getReceivePaymentForPdf}
                            getReceivePaymentForCustomers={this.props.getReceivePaymentForCustomers}
                            customer={this.state.customer}
                            {...this.props}
                          /></>
                       }
                      </div>
                    </div>
                  </div>  
                  //  console.log("Billing")
                }
                {activeUrl === "rates" &&
                  <div className="tab__body rates-body">
                    <div className="pricing__tire-text"><span>Pricing Tier:</span> {pricingTier?.pricingTier}</div>
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title">
                          Dump Rates
                        </h5>
                      </div>
                      <div className="card-body">
                        {this.state.pricingDumpRates?.length > 0 ?
                          <ul className="card__list">
                            {this.state.pricingDumpRates.map((keyName, i) =>
                              keyName !== "defaultOption" && keyName !== "capoutPrice" && keyName !== "minimumPrice" &&
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">{this.getKeyName(keyName)}</h6>
                                  <p className="sub__title">{dumpRates[keyName] ? `$${dumpRates[keyName]}` : "N/A"}</p>
                                </div>
                              </li>
                            )}
                          </ul>
                          : (
                            <div className="empty__section">
                              <div className="empty__section-inner">
                                <p className="empty__text">No Dump Rates</p>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <br />
                    <br />
                    {pricingTier?.additionalItems?.length > 0 &&
                      <div className="card">
                        <div className="card-header d-flex align-items-center justify-content-between">
                          <h5 className="card-title">
                            Fixed Items
                          </h5>
                        </div>
                        <div className="card-body">
                          <ul className="card__list">

                            {pricingTier?.additionalItems.map((item) =>
                              <li>
                                <div className="company__driver-title">
                                  <h6 className="title">{item.label}</h6>
                                  <p className="sub__title">{item.value ? `$${numberFormat(item.value)}` : "N/A"}</p>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    }
                  </div>
                }
                {activeUrl === "drivers" &&
                  <div className="tab__body drivers-body">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                      <div className="d-flex w-100">
                      <div className="w-50">
                      <h5 className="card-title">
                          Drivers
                        </h5>
                      </div>
                      <div className="w-50 d-flex justify-content-end">
                      <button onClick={() =>this.setState({ isLicenseCamera: true, createDriverSidebar: true, isDriver: false,driverAddModal:true })} className="btn btn-dark">Add new Driver</button>
                      </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table custom-table">
                            <thead>
                              <tr>
                                <th>Drivers License Photo</th>
                                <th>Driver Name</th>
                                <th>License number</th>
                                <th>Phone number</th>
                                <th >State</th>
                                <th >Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {drivers && drivers.length > 0 ? (
                                drivers.map(driver => {
                                  return (
                                    <tr className='cursor-pointer'>
                                      <td onClick={()=>this.handleEditDriver(driver)}>{driver.licenseImageUrl ? <img className="license__img" onClick={() =>
                                        this.setState({
                                          images: [_.get(driver, "licenseImageUrl", "")],
                                          isLightBoxOpen: true
                                        })
                                      } src={driver.licenseImageUrl} alt="" />:null}</td>
                                      <td onClick={()=>this.handleEditDriver(driver)}>{driver.firstName} {driver.lastName}</td>
                                      <td onClick={()=>this.handleEditDriver(driver)}>{driver.licenseNumber ? driver.licenseNumber : '-'}</td>
                                      <td onClick={()=>this.handleEditDriver(driver)}>{driver.phone ? driver.phone : '-'}</td>
                                      <td onClick={()=>this.handleEditDriver(driver)}>{driver.state ? driver.state : '-'}</td>
                                      <td>
                                        <div className="switch__button">
                                          <Switch
                                            checked={driver.status === 3 ? false : true}
                                            onChange={this.onDriverStatusChange.bind(this, driver)}
                                          />
                                          <span className={driver.status === 1 ? "text-green" : "text-red"}>
                                            {driver.status === 1 ? " Active" : " Not Active"}
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (

                                <tr>
                                  <td colSpan={10}>
                                    <div className="empty__section">
                                      <div className="empty__section-inner">
                                      {this.state.driverLoader ? (
                                            <Spin spinning={this.state.driverLoader} indicator={antIcon} />
                                          ) : (
                                            <p className="empty__text">No Drivers</p>
                                          )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                // <NoRecords title="No Drivers" />
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {activeUrl === "trucks" &&

                  <div className="tab__body trucks-body">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <div className="d-flex w-100">
                      <div className="w-50">
                      <h5 className="card-title">
                          Trucks
                          <span className="toggle-title " >Show inactive trucks
                          <Switch
                          className="ml-2 ant-switch-gray-bg"
                          checked={this.state.toggleSwtich}
                          onChange={(e) => this.handleActiveTrucks(e)}
                          />
                        </span>
                        </h5>
                      </div>
                      <div className="w-50 d-flex justify-content-end">
                      <button onClick={()=> this.setState({truckAddModal:true})} className="btn btn-dark">Add new Truck</button>
                      </div>
                        </div>
                      </div>
                      <div className="card-body">
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={this.state.isloading}>
                        <div className="table-responsive">
                          <table className="table custom-table">
                            <thead>
                              <tr>
                                <th onClick={() => this.setOrder("plateImageUrl")}>Truck's License Plate Picture{sortData["plateImageUrl"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                                <th onClick={() => this.setOrder("plateNumber")}>License plate number {sortData["plateNumber"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                                <th onClick={() => this.setOrder("number")}>Truck Number {sortData["number"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                                <th onClick={() => this.setOrder("type")}>Truck Type {sortData["type"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                                <th onClick={() => this.setOrder("defaultOutboundWeight")}>Default Outbound Truck Weight {sortData["defaultOutboundWeight"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                                <th className="status__text" onClick={() => this.setOrder("status")}>Status {sortData["status"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>

                            <tbody>
                              {trucks && trucks.length > 0 ? (
                                trucks.map((truck, i) => {
                                  return (
                                    <> 
                                    {i < 10 &&                             <TruckRow
                                     updateTruck={this.props.updateTruck}
                                     handleDrag={this.handleDrag}
                                      trucks={this.state.trucks}
                                       truckWeight={this.assignTruckWeight}
                                        customerId={this.props.customerId}
                                        fetchCustomerDetail={this.fetchCustomerDetail}
                                        handleTruckWeightChange={this.handleTruckWeightChange}
                                        updateTruckDetails={this.saveTruckDetails}
                                        key={i}
                                        i={i} 
                                        truck={truck} 
                                        containers={this.state.allContainers}
                                        onTruckStatusChange={this.onTruckStatusChange}
                                        editTruck={this.editTruck}
                                        />
                                    }
                                        </>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={10}>
                                    <div className="empty__section">
                                      <div className="empty__section-inner">
                                        <p className="empty__text">No Trucks</p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                        </Spin>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center text-center mt-3">
                      <button className="btn btn-dark btn-xs mr-2 btn-previous" onClick={() => this.onTruckChangePage('prev')}disabled={this.state.currentTruckPage <= 1 || this.state.trucks.length === 0}><RightCaretIcon/>  Previous</button>
                      <button className="btn btn-dark btn-xs btn-next" onClick={() => this.onTruckChangePage('next')} disabled={!(this.state.trucks.length > 10 )}>Next  <RightCaretIcon/></button>
                    </div>
                  </div>  
                }
                {activeUrl === "payment-methods" &&
                  <div className="tab__body drivers-body">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title">
                          Payment Methods
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table custom-table">
                            <thead>
                              <tr>
                                <th>Card Number</th>
                                <th>Card Type</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.customerPayabliData?.StoredMethods?.length > 0 ? (
                                this.state.customerPayabliData?.StoredMethods?.map((item,index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.MaskedAccount}</td>
                                      <td>{item.Descriptor}</td>
                                      <td className="text-center">
                                      <span className={"text-green"}>
                                          Active
                                          </span>
                                      </td>
                                      <td className="d-flex align-items-center justify-content-center">
                                      <button  onClick={()=> this.deletePaymentMethod({_id:item?.IdPmethod})} className="delete-btn-table">Delete</button>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : !this.state.customer?.payabliCustomer?.customerId && this.state?.customer?.paymentmethods?.length > 0 ? 
                                this.state?.customer?.paymentmethods?.map((item,index) => {
                                  return (
                                    <tr key={index}>
                                      <td>**** {item.details?.last4digits}</td>
                                      <td>{item.details?.brand}</td>
                                      <td className="text-center">
                                      <span className={"text-green"}>
                                          Active
                                          </span>
                                      </td>
                                      <td className="d-flex align-items-center justify-content-center">
                                      <button  onClick={()=> this.deletePaymentMethod({_id:item?._id})} className="delete-btn-table">Delete</button>
                                      </td>
                                    </tr>
                                  );
                                }):
                               (

                                <tr>
                                  <td colSpan={10}>
                                    <div className="empty__section">
                                      <div className="empty__section-inner">
                                        <p className="empty__text">{this.state.isLoadingPayment ? <Spin indicator={antIcon}/> : "No Payment Method"}</p>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {activeUrl === "history" && (
  <div className="tab__body history__body">
    {this?.state?.logData?.length > 0 && (this?.state?.logData?.length > 1 || this?.state?.logData[0]?.logType === "create" || this?.state?.logData[0]?.logType === "update") ? (
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title">Logs Information</h5>
        </div>
        <div className="card-body">
          {this?.state?.logData?.map((logEntry, index) => (
            <div key={index}>
              {logEntry.logType === "create" && "update"? (
                <div>
                  Customer <b>{logEntry.displayName}</b> was created by <b>{logEntry.userdata?.firstName}</b> on {moment(logEntry.updatedAt)?.tz(this.state?.tz)?.format('l')} at {moment(logEntry.updatedAt)?.tz(this.state?.tz)?.format('LT')}.
                </div>
              ) : (
                <div>
                  {logEntry.changedFields && (
                    <div>
                      {logEntry.changedFields.companyName && (
                        <p>
                          Company name changed from <b>{logEntry.changedFields.companyName.oldValue === '' ? "Blank/Empty" : logEntry.changedFields.companyName.oldValue}</b> to <b>{logEntry.changedFields.companyName.newValue === '' ? "Blank/Empty" : logEntry.changedFields.companyName.newValue}</b> by <b>{logEntry.userdata?.firstName}</b> on {moment(logEntry.updatedAt)?.tz(this.state?.tz)?.format('l')} at {moment(logEntry.updatedAt)?.tz(this.state?.tz)?.format('LT')}.
                        </p>
                      )}

                      {logEntry.changedFields.displayName && (
                        <p>
                          Display name changed from <b>{logEntry.changedFields.displayName.oldValue}</b> to <b>{logEntry.changedFields.displayName.newValue}</b> by <b>{logEntry.userdata?.firstName}</b> on {moment(logEntry.updatedAt)?.tz(this.state?.tz)?.format('l')} at {moment(logEntry.updatedAt)?.tz(this.state?.tz)?.format('LT')}.
                        </p>
                      )}
                        {logEntry.changedFields?.pricingTier && (
                          <p>
                            Pricing Tier changed from <b>{this.state?.logData[index + 1]?.pricingtier_Data?.pricingTier || "Blank/Empty"}</b> to <b>{logEntry.pricingtier_Data?.pricingTier || "Blank/Empty"}</b> by <b>{logEntry.userdata?.firstName}</b> on {moment(logEntry.updatedAt)?.tz(this.state?.tz)?.format('l')} at {moment(logEntry.updatedAt)?.tz(this.state?.tz)?.format('LT')}.
                          </p>
                      )}
                      {logEntry.changedFields.paymentTerms && (
                        <p>
                         Payment Terms changed from <b>{logEntry.changedFields.paymentTerms.oldValue=== "account" ? "Account" : "Cod"}</b> to <b>{logEntry.changedFields.paymentTerms.newValue === "account" ? "Account" : "Cod"}</b> by <b>{logEntry.userdata?.firstName || " N/A "}</b> on {moment(logEntry.updatedAt)?.tz(this.state?.tz)?.format('l')} at {moment(logEntry.updatedAt)?.tz(this.state?.tz)?.format('LT')}.
                        </p>
                      )}
                    </div>
                  )}
                </div>
                
              )}
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <h5 className="card-title">Logs Information</h5>
        </div>
        <div className="card-body">
          <div className="empty__section">
            <div className="empty__section-inner">
              <p className="empty__text">There is no log data for this customer.</p>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
)}



              </section>
            </div>
          </div>
        </ReactModal>
        {this.state.EditCustomerModelOpen &&
          <EditCustomer
            id={this.state.customerId}
            EditCustomerModelOpen={this.state.EditCustomerModelOpen}
            closeModal={this.closeModal}
          />
        }
        {this.state.truckAddModal &&
          <AddTruckContainer
            customer={this.state.customer}
            trucks={this.state.trucks}
            truckAddModal={this.state.truckAddModal}
            closeModal={this.closeTruckModal}
            customerDetails={this.fetchCustomerDetail}
          />
        }
        {this.state.editTruckPopup &&
          <AddTruckContainer
            customer={this.state.customer}
            trucks={this.state.trucks}
            truckAddModal={this.state.editTruckPopup}
            editTruckData={this.state.editTruckData}
            closeModal={this.closeTruckModal}
            customerDetails={this.fetchCustomerDetail}
            isForEdit={true}
            updateTruck={this.props.updateTruck}
          />
        }
        {this.state.createStatementPopup && 
        <CreateStatementComponent
        customerId={this.state.customer.customerId}
        customer={this.state.customer}
        onClose={() => {
          this.setState({ createStatementPopup: false})
        }}
        customerOpenBalanceInvoicesByCustomerId={this.props.customerOpenBalanceInvoicesByCustomerId}
        getCustomercreditsForCustomers={this.props.getCustomercreditsForCustomers}
        sendInvoiceEmail={this.props.sendInvoiceEmail}
        printByDropDown={this.state.isPrintRecipt}
        getCompanyById={this.props.getCompanyById}
        generateStatementPdf={this.props.generateStatementPdf}
        statementEmail={this.props.statementEmail}
        />

        }
        {this.state.driverAddModal &&
          <AddDriverContainer
            customer={this.state.customer}
            trucks={this.state.trucks}
            truckAddModal={this.state.driverAddModal}
            closeModal={this.closeDriverModal}
            customerDetails={this.fetchCustomerDetail}
            drivers={drivers}
            isLicenseCamera={this.state.isLicenseCamera} 
            createDriverSidebar={this.state.createDriverSidebar} 
            isDriver={this.state.isDriver}
            isForEdit={false}
            user={this.state.user}
          />
        }
        {this.state.driverEditModal &&
          <AddDriverContainer
            customer={this.state.customer}
            trucks={this.state.trucks}
            truckAddModal={this.state.driverAddModal}
            closeModal={this.closeDriverModal}
            editDriverData={this.state.editDriverData}
            customerDetails={this.fetchCustomerDetail}
            drivers={drivers}
            isLicenseCamera={this.state.isLicenseCamera} 
            createDriverSidebar={this.state.createDriverSidebar} 
            isDriver={this.state.isDriver}
            isForEdit={true}
            user={this.state.user}
          />
        }
        {this.state.isModelOpen && (
          <InvoiceDetailComponent
            updateinvoiceDetailData={this.updateinvoiceDetailData}
            isModelOpen={this.state.isModelOpen}
            closeModel={this.invoiceDetailCloseModel}
            modelData={this.state.modelData}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            projectData={this.state.projectDetails}
            printByDropDown={this.state.isPrintRecipt}
            uploadImage={this.props.uploadImage}
            updateInvoiceOrder={this.props.updateInvoiceOrder}
            updateInvoicDetail={this.props.updateInvoicDetail}
            getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
            reArrangOrdersData={this.reArrangOrdersData}
            getOrderInvoicePdf={this.props.getOrderInvoicePdf}
            fetchOrder={this.props.fetchOrder}
            getReceivePaymentForPdf={this.props.getReceivePaymentForPdf}
            {...this.props}
          />
        )}
        {this.state.isDeleteModal ? <DeleteUserModal
          isDeleteModal={this.state.isDeleteModal}
          closeModal={() => {
            this.setState({ isDeleteModal: false, ismodelOpen:true  })
          }}
          deletePaymentMethod={this.props.deletePaymentMethod}
          closeModalAfterDelete={()=>{
            this.setState({ isDeleteModal: false, ismodelOpen:true ,customerPayabliData:{
            StoredMethods:[]
            }, })
            this.fetchCustomerDetail(this.props.customerId)
            this.getCustomerPayment(this.state.customer?.payabliCustomer?.customerId)
          }}
          paymentMethodId={this.state.paymentMethodId}
        />:""}
      </div>
    );
  }
}
