import { connect } from "react-redux";
import {  uploadImage,fetchMe,updateUserToken } from "../../store/user/duck";
import { fetchOrder, updateOrder, sendEmailReceipt, deleteOrder, updateTotalAmount, getOrderInvoicePdf,printOrderDetails,printPerforatedOrder,updateOrderData } from "../../store/order/duck";
import CompletedOrderDetailsComponent from "./component";
import {getPricingTierById} from "../../store/Pricing_tier/duck"
import {fetchSetting} from '../../store/company/duck'

const CompletedOrderDetailsContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase
  }),
  {
    getOrderInvoicePdf,
    fetchOrder,
    updateUserToken,
    updateOrder,
    fetchMe,
    sendEmailReceipt,
    uploadImage,
    deleteOrder,
    updateTotalAmount,
    getPricingTierById,
    fetchSetting,
    printPerforatedOrder,
    printOrderDetails,
    updateOrderData
  }
)(CompletedOrderDetailsComponent);

export default CompletedOrderDetailsContainer;
