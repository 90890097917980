import { fetch } from '../../utils'
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;


export const fetchNeedReview = (payload) => {
  return fetch(`${HOSTNAME}/get_need_review?searchText=${escape(payload.searchText)}&startDate=${payload.startDate}&endDate=${payload.endDate}&filterList=${JSON.stringify(payload.filterList)}&searchStartsWith=${payload.searchStartsWith ? payload.searchStartsWith: false}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const fetchCustomerOpenBalance = (payload) => {
  return fetch(`${HOSTNAME}/fetch_customer_open_balance`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const fetchPostedOrder = (payload) => {
  return fetch(`${HOSTNAME}/posted_Orders?tabtype=posted&searchText=${escape(payload.searchText)}&startDate=${payload.startDate}&endDate=${payload.endDate}&skip=${payload.skip}&limit=${payload.limit}&filterList=${JSON.stringify(payload.filterList)}&searchStartsWith=${payload.searchStartsWith ? payload.searchStartsWith: false}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const fetchPostedInvoicesCount = (payload) => {
  return fetch(`${HOSTNAME}/posted_order_invoice_amounts?tabtype=${payload?.tabtype}&searchText=${escape(payload.searchText)}&startDate=${payload.startDate}&endDate=${payload.endDate}&skip=${payload.skip}&limit=${payload.limit}&filterList=${JSON.stringify(payload.filterList)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const getInvoiceDetailsById = (payload) => {
  return fetch(`${HOSTNAME}/get_all_invoiceDetail?invoiceId=${payload.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const getInvoiceDetailsByPrId = (payload) => {
  console.log("Data",`${HOSTNAME}/get_all_paymentInvDetails?invoiceId=${payload.id}`)
  return fetch(`${HOSTNAME}/get_all_paymentInvDetails?invoiceId=${payload.id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const getInvoiceDataForEmail = (payload) => {
  return fetch(`${HOSTNAME}/get_Invoice_Data_By_Id?invoiceId=${(payload.invoiceId)}&companyId=${payload.companyId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
export const fetchFinalizedInvoice = (payload) => {
  return fetch(`${HOSTNAME}/posted_Orders?tabtype=finalized&searchText=${escape(payload.searchText)}&startDate=${payload.startDate}&endDate=${payload.endDate}&skip=${payload.skip}&limit=${payload.limit}&filterList=${JSON.stringify(payload.filterList)}&searchStartsWith=${payload.searchStartsWith ? payload.searchStartsWith: false}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const getRecivingPaymentInvoices = (payload) => {
  return fetch(`${HOSTNAME}/get_customer_invoices?customerId=${payload.customerId}&billingStatus=${payload.billingStatus}&skip=${payload.skip}&limit=${payload.limit}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const getCustomercredits = (payload) => {
  return fetch(`${HOSTNAME}/get-customer-credit?customerId=${payload.customerId}&skip=${payload.skip}&limit=${payload.limit}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const getCustomercreditsForCustomers = (payload) => {
  return fetch(`${HOSTNAME}/get-credits-by-customerId?customerId=${payload.customerId}&skip=${payload.skip}&limit=${payload.limit}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const getReceivePaymentForCustomers = (payload) => {
  return fetch(`${HOSTNAME}/get-receive-Payments?customerId=${payload.customerId}&skip=${payload.skip}&limit=${payload.limit}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const customerInvoicesByCustomerId  = (payload) => {
  return fetch(`${HOSTNAME}/fetch_customer_invoices_by_customerId?customerId=${payload.customerId}&skip=${payload.skip}&limit=${payload.limit}&billingStatus=${payload.billingStatus}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
export const customerOpenBalanceInvoicesByCustomerId  = (payload) => {
  return fetch(`${HOSTNAME}/fetch_customer_open_balance_invoices_by_customerId?customerId=${payload.customerId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const getReceivePaymentForPDF = (payload) => {
  return fetch(`${HOSTNAME}/get-receive-Payments-for-pdf?invoiceId=${payload.invoiceId}&companyId=${payload.companyId ?? ""}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
export const getInvoiceDetalByInvoiceId = (payload) => {
  return fetch(`${HOSTNAME}/get_all_invoiceDetail?invoiceId=${payload.invoiceId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
export const getCompanyById = (payload) => {
  return fetch(`${HOSTNAME}/company/${payload.companyId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
export const getProjectByProjectId = (payload) => {
  return fetch(`${HOSTNAME}/projectsByID/${payload.projectId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const syncPostedOrder = (payload) => {
  return fetch(`${HOSTNAME}/sync-posted-order`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
export const batchPrintInvoice = (payload) => {
  return fetch(`${HOSTNAME}/batch-print-invoices`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
  .then(res => {
    return res.blob()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const syncBulkPostedOrder = (payload) => {
  return fetch(`${HOSTNAME}/sync-bulk-posted-order`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

export const updateReceievePayment = (payload) => {
  return fetch(`${HOSTNAME}/ReceievePayment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

// export const updateInvoiceOrder = (payload) => {
//   return fetch(`${HOSTNAME}/invoice_order}`, {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify(payload)
//   })
//     .then(res => {
//       return res.json();
//     })
//     .then(payload => {
//       return payload;
//     })
//     .catch(error => {
//       throw error;
//     });
// };

export const updateInvoiceOrder = (paylod) => {
  return fetch(`${HOSTNAME}/invoice_order`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(paylod)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
export const movedInvoiceBulkAction = (paylod) => {
  return fetch(`${HOSTNAME}/moved_invoice_bulk_action`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(paylod)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const updateInvoicDetail = (paylod) => {
  return fetch(`${HOSTNAME}/invoice_details`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(paylod)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};


export const sendInvoiceEmail = (paylod) => {
  return fetch(`${HOSTNAME}/invoice_email`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(paylod)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const sendInvoiceEmail_2 = (paylod) => {
  return fetch(`${HOSTNAME}/invoice_email_2`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(paylod)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const sendReminderInvoiceEmail = (paylod) => {
  console.log(paylod)
  return fetch(`${HOSTNAME}/reminder_invoice_email`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(paylod)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const downloadBulkInvoice = (payload) => {
  return fetch(`${HOSTNAME}/generate-invoice-pdf`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
}


export const payInvoice = (paylod) => {
  console.log(paylod)
  return fetch(`${HOSTNAME}/pay_invoice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(paylod)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};


export const checkPabliIntegration = (payload) => {
  return fetch(`${HOSTNAME}/paybli-integration?companyId=${payload.companyId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    // body: JSON.stringify(paylod)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const updatePaymentInfo = (payload) => {
  return fetch(`${HOSTNAME}/update-payment-info`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
export const generateStatementPdf = (payload) => {
  return fetch(`${HOSTNAME}/generate-statement-pdf`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
export const statementEmail = (payload) => {
  return fetch(`${HOSTNAME}/statement_email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
export const printPaymentReciept = (payload) => {
  return fetch(`${HOSTNAME}/print-payment-recipt`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.blob();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getQbDetails = (payload) => {
  return fetch(`${HOSTNAME}/qb-details`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
