import React, { PureComponent } from "react";
import { Switch, Pagination, Spin } from "antd";
import TopNavigation from "./../TopNavigation/container";
import SidebarNavigation from "./../SidebarNavigation/component";
import _ from "lodash";
import "./styles.scss";
import NotFound from "../NoRecords/component";
import NumberFormat from "react-number-format";
import { DownarrowIcon, StateUpArrowIcon } from "../../components/icons";
import { LoadingOutlined } from "@ant-design/icons";
import CreateCustomer from '../CreateCustomer/container'
import CustomerDetailComponent from "../CustomerDetail/component";
import SearchCheckboxComponent from "../Common/SearchCheckbox";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default class CustomersComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchStartsWith: true,
      user: {},
      customers: [],
      searchText: "",
      typingTimeout: 0,
      toggleMenu: false,
      sortOrder: { displayName: 1 },
      totalItems: 0,
      skip: 0,
      limit: 20,
      currentPage: 1,
      spinning: false,
      mainSearchText: "",
      createCustomerModelOpen:false,
      isCustomerModelOpen:false,
      customerId:'',
      defaultUrl:"customers",
      toggleSwtich: false
    };
  }

  setAsyncState = newState => new Promise(resolve => this.setState(newState, resolve));

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    document.title = "Customers | Weighworks";
    const sortOrder = localStorage.getItem("customerSort");
    if (sortOrder) {
      await this.setAsyncState({ sortOrder: JSON.parse(sortOrder) });
    }
    this.fetchCustomers();
  const urlarray=window.location.pathname.split('/')
    if(urlarray[3]==="past-weigh-ins"){
      this.setState({isCustomerModelOpen:true})
    }
    if(urlarray[3]==="general"){
      this.setState({isCustomerModelOpen:true})
    }
    if (urlarray[3] === "billing" && urlarray[4] === "invoice") {
      this.setState({ isCustomerModelOpen:true });
    }
    if (urlarray[3] === "billing" && urlarray[4] === "payment-receipt") {
      this.setState({ isCustomerModelOpen:true , customerId: urlarray[2] });
    }
    if (urlarray[3] === "billing" && urlarray[4] === "credit-memo") {
      this.setState({ isCustomerModelOpen:true });
    }
  };

   handleSearch = event => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: event.target.value,
      typingTimeout: setTimeout(() => {
        this.fetchCustomers();
      }, 1000)
    });
  };
  
  closeCreateCustomerModel= (value)=>{
    this.setState({createCustomerModelOpen:false})
    if(value === "customerAdded"){
      this.fetchCustomers();
    }
    
  }

  handleMainSearchText(event) {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    const search = event.target.value.trimStart() !== ""
    this.setState({
      skip: 0,
      currentPage: 1,
      mainSearchText: event.target.value.trimStart(),
      typingTimeout: setTimeout(() => {
        this.fetchCustomers(search);
      }, 500)
    });
  }

  async fetchCustomers(search) {
    const { fetchCustomers } = this.props;
    const { searchText, sortOrder, skip, limit, mainSearchText,toggleSwtich } = this.state;
    // this.setState({ spinning: true });
    const {
      value: {
        data: { customers, totalCustomers }
      }
    } = await fetchCustomers({ searchText: searchText ? searchText : mainSearchText, sortOrder, skip:search ? 0 : skip, limit,inActiceCustomers:toggleSwtich ? true : false, searchStartsWith: this.state.searchStartsWith });
    this.setState({ customers, totalItems: totalCustomers, spinning: false });
  }

   assignUrl=(url)=>{
    let customerId="";
    const path = url.split("/")
    console.log(path)
    if( path[1]!=="order-details" && this.state.customerId===""){
      customerId=path[2]
    }else{
        customerId=this.state.customerId
    }
    if(path[1]==="order-details" || path[1]==="edit-customer"){
      if(path[1]==="order-details"){
        this.props.history.push(url)
        this.setState({ isCustomerModelOpen:false });
      }
      if(path[1]==="edit-customer"){
        this.props.history.push({
          pathname: "/edit-customer",
          state: {
            id: customerId
          }
        })
      }
   
    }
   else {
    if (path[3] === "general") {
      this.props.history.push(`/customer-detail/${customerId}/general`)
      this.setState({ isCustomerModelOpen:true });
    }
    if (path[3] === "past-weigh-ins") {
      this.props.history.push(`/customer-detail/${customerId}/past-weigh-ins`)
      this.setState({ isCustomerModelOpen:true });
    }
    if (path[3] === "projects") {
      this.props.history.push(`/customer-detail/${customerId}/projects`)
      this.setState({ isCustomerModelOpen:true });
    }
    if (path[3] === "billing" && path[4] === "invoice") {
      this.props.history.push(`/customer-detail/${customerId}/billing/invoice`)
      this.setState({ isCustomerModelOpen:true });
    }
    if (path[3] === "billing" && path[4] === "payment-receipt") {
      this.props.history.push(`/customer-detail/${customerId}/billing/payment-receipt`)
      this.setState({ isCustomerModelOpen:true });
    }
    if (path[3] === "billing" && path[4] === "credit-memo") {
      this.props.history.push(`/customer-detail/${customerId}/billing/credit-memo`)
      this.setState({ isCustomerModelOpen:true });
    }
    if (path[3] === "rates") {
      this.props.history.push(`/customer-detail/${customerId}/rates`)
      this.setState({ isCustomerModelOpen:true });
    }
    if (path[3] === "drivers") {
      this.props.history.push(`/customer-detail/${customerId}/drivers`)
      this.setState({ isCustomerModelOpen:true });
    }
    if (path[3] === "trucks") {
      this.props.history.push(`/customer-detail/${customerId}/trucks`)
      this.setState({ isCustomerModelOpen:true });
    }
    if (path[3] === "payment-methods") {
      this.props.history.push(`/customer-detail/${customerId}/payment-methods`)
      this.setState({ isCustomerModelOpen:true });
    }
    if (path[3] === "history") {
      this.props.history.push(`/customer-detail/${customerId}/history`)
      this.setState({ isCustomerModelOpen:true });
    }
  }
   }
   closeModel=()=>{
    this.setState({isCustomerModelOpen:false})
    this.props.history.push("/customers")
    this.fetchCustomers()
   }

  onToggleChange = async customer => {
    const payload = {
      _id: customer._id,
      status: customer.status === 1 ? 3 : 1
    };
    const {
      value: { status }
    } = await this.props.updateCustomer(payload);
    if (status) {
      this.fetchCustomers();
    }
  };

  setOrder(field) {
    const { sortOrder } = this.state;
    const sort = { [field]: sortOrder[field] === 1 ? -1 : 1 };
    localStorage.setItem("customerSort", JSON.stringify(sort));
    this.setState({ sortOrder: sort }, () => {
      this.fetchCustomers();
    });
  }

  onChangePage = page => {
    this.setState({ currentPage: page, skip: (page - 1) * this.state.limit }, () => {
      this.fetchCustomers();
    });
  };

  handleActiveCustomer = async (checked) => {
    if (checked) {
      this.setState({ toggleSwtich: true,currentPage:1,skip: 0,limit: 20 },()=>this.fetchCustomers());
    } else {
      this.setState({ toggleSwtich: false,currentPage:1,skip: 0,limit: 20  },()=>this.fetchCustomers());
    }
  }

  render() {
    const { customers, searchText, sortOrder, mainSearchText } = this.state;
    const { user } = this.props;
    return (
      <div>
        <div className="layout-has-sidebar">
          <TopNavigation
            {...this.props}
            searchText={searchText}
            handleSearchChange={this.handleSearch}
            isCustomerModelOpen={()=> this.setState({isCustomerModelOpen: true})}
            onClickToggleMenu={() => this.setState({ toggleMenu: !this.state.toggleMenu })}
          />
          <SidebarNavigation
            {...this.props}
            toggleMenu={this.state.toggleMenu}
            onClickToggleMenu={() => this.setState({ toggleMenu: false })}
          />
         
          <main className="dashboard-layout-content customers__wrapper">
            <div className="page-header">
              <h2 className="heading-title ">Customers
              <span className="toggle-title" >Show inactive customers
                <Switch
                className="ant-switch-gray-bg"
                checked={this.state.toggleSwtich}
                onChange={(e) => this.handleActiveCustomer(e)}
                />
              </span>
              </h2>
              {(user?.permissions?.permissionOptions?.createCustomer === true) &&
              <div className="page-header-btn-grp">
              <button className="btn btn-dark btn-sm" onClick={()=>{this.setState({createCustomerModelOpen:true},()=>this.fetchCustomers() )}}>Add Customer</button>
              </div>
              }
            </div>
            <div className="search__box">
              <input
                className="form-control"
                placeholder="Customer name, address, phone number or email..."
                value={mainSearchText}
                onChange={e => this.handleMainSearchText(e)}
              />
              <SearchCheckboxComponent
                searchStartsWith={this.state.searchStartsWith}
                handleSearchStartsWith={(value) => this.setState({ searchStartsWith: value, skip: 0, currentPage: 1 }, () => this.fetchCustomers())}
              />
            </div>
            <Spin indicator={antIcon} spinning={this.state.spinning}>
              {customers.length !== 0 ? (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table custom-table">
                          <thead>
                            <tr>
                              <th onClick={() => this.setOrder("displayName")}>
                                Customer Name {sortOrder["displayName"] === 1 ? <StateUpArrowIcon /> : <DownarrowIcon />}
                              </th>
                              <th>Address</th>
                              <th>Phone Number</th>
                              <th>Email</th>
                              <th>Contact Name</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {customers.map(customer => {
                              return (
                                <tr className="cursor-pointer" key={customer._id}>
                                  <td
                                    onClick={() =>{
                                      this.setState({isCustomerModelOpen:true,customerId:customer.customerId})
                                      this.props.history.push(`/customer-detail/${customer.customerId}/general`)
                                    }
                                    }
                                  >
                                    {_.get(customer, "displayName", "")}
                                  </td>
                                  <td
                                    onClick={() =>{
                                      this.setState({isCustomerModelOpen:true,customerId:customer.customerId})
                                      this.props.history.push(`/customer-detail/${customer.customerId}/general`)
                                    }
                                    }
                                  >
                                    <div className="city__addresss">
                                      {customer.address && customer.address !== "" ? customer.address : "-"}
                                    </div>
                                    {customer.city !== "" && customer.state !== "" ? (
                                      <div className="state__addresss">
                                        {customer.city}, {customer.state} {customer.zip}
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>{
                                      this.setState({isCustomerModelOpen:true,customerId:customer.customerId})
                                      this.props.history.push(`/customer-detail/${customer.customerId}/general`)
                                    }}
                                  >
                                    {customer.phone && customer.phone !== "" ? (
                                      <NumberFormat
                                        format="(###) ###-####"
                                        allowEmptyFormatting={false}
                                        mask=""
                                        displayType={"text"}
                                        value={customer.phone}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>{
                                      this.setState({isCustomerModelOpen:true,customerId:customer.customerId})
                                      this.props.history.push(`/customer-detail/${customer.customerId}/general`)
                                    }}
                                  >
                                    {customer.billingEmailAddress && customer.billingEmailAddress !== "" ? customer.billingEmailAddress : "-"}
                                  </td>
                                  <td
                                    onClick={() =>{
                                      this.setState({isCustomerModelOpen:true,customerId:customer.customerId})
                                      this.props.history.push(`/customer-detail/${customer.customerId}/general`)
                                    }}
                                  >
                                    {_.startCase(_.toLower(customer.billingContactName))}
                                  </td>
                                  <td className={customer.status === 1 ? "text-green" : "text-red"}>
                                    {customer.status === 1 ? "Active" : "Not Active"}
                                    <Switch
                                      checked={customer.status === 3 ? false : true}
                                      onChange={() => this.onToggleChange(customer)}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination
                    className="text-center"
                    onChange={this.onChangePage}
                    hideOnSinglePage
                    current={this.state.currentPage}
                    total={this.state.totalItems}
                    pageSize={this.state.limit}
                    showSizeChanger={false}
                  />
                </>
              ) : (
                <NotFound title="No Customers" />
              )}{" "}
            </Spin>
            {this.state.createCustomerModelOpen &&
            <CreateCustomer
            createCustomerModelOpen={this.state.createCustomerModelOpen}
            closeModel={this.closeCreateCustomerModel}
            />}
          </main>
          { this.state.isCustomerModelOpen &&
            <CustomerDetailComponent
            syncPostedOrder={this.props.syncPostedOrder}
            customerId={this.state.customerId}
            getMe={this.props.getMe}
            fetchCustomer={this.props.fetchCustomer}
            changeUrl={this.assignUrl}
            closeModel={this.closeModel}
            updateDriver={this.props.updateDriver}
            updateTruck={this.props.updateTruck}
            deletePaymentMethod={this.props.deletePaymentMethod}
            getPayabliCustomerData={this.props.getPayabliCustomerData}
            defaultUrl={this.state.defaultUrl}
            getInvoiceDeliveryByIdData={this.props.getInvoiceDeliveryByIdData}
            getRecivingPaymentInvoices={this.props.getRecivingPaymentInvoices}
            getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
            updateInvoicDetail={this.props.updateInvoicDetail}
            getCustomercreditsForCustomers={this.props.getCustomercreditsForCustomers}
            customerOpenBalanceInvoicesByCustomerId={this.props.customerOpenBalanceInvoicesByCustomerId}
            getCompanyById={this.props.getCompanyById}
            generateStatementPdf={this.props.generateStatementPdf}
            getReceivePaymentForCustomers={this.props.getReceivePaymentForCustomers}
            statementEmail={this.props.statementEmail}
            customerInvoicesByCustomerId={this.props.customerInvoicesByCustomerId}
            getOrderInvoicePdf={this.props.getOrderInvoicePdf}
            getReceivePaymentForPdf={this.props.getReceivePaymentForPdf}
            updateTruckWithIndex={this.props.updateTruckWithIndex}
            {...this.props}
            
          />
          }
        </div>
      </div>
    );
  }
}
