import React, { PureComponent } from 'react';
import _ from "lodash";
import { numberFormatLbs } from "../../../constants/common";
import { Dropdown, Switch } from "antd";
import { MoreIcon } from '../../../components/icons';



class TruckRow extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            addIndex: this.props.addIndex,
            edit: false,
            addContainer: false,
            truckContainers:this.props.truck.containers?.sort((a, b) => Number(a.size) - Number(b.size)),
            newContainer: {},
            editContainerIndex: null,
            reRender: false
        }
    }


    handleTruckWeightChange(e, i) {
        const trucks = [...this.props.trucks];
        const val = e.target.value === "" ? 0 : e.target.value.replace(/[^\d]/g, "");
        trucks[i].defaultOutboundWeight = val;
        this.props.truckWeight({ trucks });
    }

    async updateTruckDetails(truck, container) {
        const { updateTruck } = this.props;
        const payload = container ? {
            _id: truck._id,
            defaultOutboundWeight: Number(truck.defaultOutboundWeight ?? 0),
            containers: this.state.truckContainers
        } : {
            _id: truck._id,
            defaultOutboundWeight: Number(truck.defaultOutboundWeight ?? 0),
        }
        const {
            value: { status }
        } = await updateTruck(payload);
        if (status) {
            await this.props.fetchCustomerDetail(this.props.customerId);
            this.setState({ editContainerIndex: null, truckContainers: this.props.truck.containers, reRender: !this.state.reRender ,edit:false})
        }
    }

    cancelEdit = async () => {
        await this.props.fetchCustomerDetail(this.props.customerId);
        this.setState({ editContainerIndex: null, reRender: !this.state.reRender, truckContainers: this.props.truck.containers });
    }

    onTruckStatusChange = async truck => {
        const { updateTruck, customerId, fetchCustomerDetail } = this.props;
        const payload = {
            _id: truck._id,
            status: truck.status === 1 ? 3 : 1
        };
        const {
            value: { status }
        } = await updateTruck(payload);
        if (status) {
            fetchCustomerDetail(customerId);
        }
    };

    changeContainer = (e) => {
        if (e !== "") {
            const data = JSON.parse(e)
            this.setState({ newContainer: data })
        }
    }
    async handleContainerWeightChange(e) {
        const container = this.state.newContainer;
        container.defaultWeight = e.target.value === "" ? 0 : Number(e.target.value.replace(/[^\d]/g, ""));
        await this.setState({ newContainer: container, reRender: !this.state.reRender })
    }

    saveNewContainer = async (truck) => {
        const { newContainer, truckContainers } = this.state;
        const { updateTruck, customerId, fetchCustomerDetail } = this.props;
        const payload = {
            _id: truck._id,
            containers: [...truckContainers, newContainer]
        };
        const {
            value: { status }
        } = await updateTruck(payload);
        if (status) {
            await fetchCustomerDetail(customerId);
            this.setState({ addContainer: false, truckContainers: this.props.truck.containers, reRender: !this.state.reRender, newContainer: {} })
        }
    }

    editTruckContainer = async (e, index) => {
        const { name, value } = e.target
        const containers = this.state.truckContainers
        containers[index][name] = Number(value.replace(/[^\d]/g, ""))
        if (name === "size") {
            containers[index][name] = String(value)
            const check = this.props.containers.find(v => v.size === String(value));
            if (check) {
                containers[index]["defaultWeight"] = check.defaultWeight
            }
        }
        this.setState({ truckContainers: containers, reRender: !this.state.reRender })

    }

    checkAlready = (size, editVal) => {
        const { truckContainers } = this.state
        // const check = truckContainers.find(v => Number(v.size) === Number(size));
        const check = truckContainers.find(v => String(v.size) === String(size));
        return check ? check.size === editVal ? false : true : false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.truck.containers !== this.props.truck.containers) {
            if( this.props.truck.containers.length > 0){
                this.props.truck.containers = this.props.truck.containers?.sort((a, b) => Number(a.size) - Number(b.size));
            }
            this.setState({ truckContainers: this.props.truck.containers })
        }
    }

    render() {
        const { edit, truckContainers, editContainerIndex } = this.state
        const { i, truck, containers } = this.props;
        return <tr
        key={i}
        draggable
        onDrag={(e) => e.preventDefault()}
        onDragStart={(e) => e.dataTransfer.setData('dragIndex', i)}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          const fromIndex = e.dataTransfer.getData('dragIndex');
          const toIndex = i;

          this.props.handleDrag(e, fromIndex, toIndex);
        }}
        >
            <td><img onClick={() =>
                this.setState({
                    images: [_.get(truck, "plateImageUrl", "")],
                    isLightBoxOpen: true
                })
            } className="license__img" src={truck?.plateImageUrl} alt="" /></td>
            <td>  {truck?.plateNumber}</td>
            <td>{truck?.number}</td>
            <td>{_.startCase(truck?.type)}</td>
            {this.state.addContainer ?
                <td className={"add__new__container "}>
                    <div className="d-flex add__new__container px-2">
                        <div className="top-margin-10 col-4 p-0">
                            <div className="form-group material-textfield">
                                <select
                                    className="form-control custom-select w-150 material-textfield-input pr-22"
                                    // required
                                    // value={values.type}
                                    onChange={(e) => this.changeContainer(e.target.value)}
                                // name="type"
                                >
                                    <option value={""}>Select Container</option>
                                    {containers.map((v, i) => {
                                        const disabled = this.checkAlready(v.size)
                                        return <option disabled={disabled} key={i} value={JSON.stringify(v)}>{v.size}</option>
                                    })}
                                </select>
                                <label className="material-textfield-label">
                                    Container Size<span>*</span>
                                </label>
                            </div>
                        </div>
                        <div className="top-margin-10 col-4 p-0">
                            <div className="form-group material-textfield">
                                <input
                                    className={`form-control m-10-  pointer-events`}
                                    type="text"
                                    value={this.state.newContainer?.defaultWeight ? numberFormatLbs(this.state.newContainer?.defaultWeight):""}
                                    onChange={e => {
                                        this.handleContainerWeightChange(e)
                                    }}
                                />
                                <label className="material-textfield-label">
                                    Default Weight<span>*</span>
                                </label>
                            </div>
                        </div>
                        <div className="editable__button col-4 p-0">
                            <button disabled={!this.state.newContainer?.size || !!!this.state.newContainer?.defaultWeight} onClick={() => this.saveNewContainer(truck)} className="save_btn editable__btn">
                                Save
                            </button>
                            <button
                                className="cancel_btn editable__btn"
                                onClick={() => {
                                    this.setState({
                                        addContainer: false, newContainer: {}
                                    })
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div></td> : truckContainers?.length > 0 ?
                    <td className={editContainerIndex !== null && "add__new__container"}>
                        {truckContainers?.map((v, i) => <div className={editContainerIndex !== null ? "add__new__container" : "editable__table_field-section"}>
                            {editContainerIndex === i ?
                                <div className="d-flex add__new__container px-2">
                                    <div className="top-margin-10  col-4 p-0">
                                        <div className="form-group material-textfield">
                                            <select
                                                className="form-control custom-select w-150 material-textfield-input pr-22"
                                                // required
                                                value={v.size}
                                                onChange={(e) => this.editTruckContainer(e, i)}
                                                name="size"
                                            >
                                                {containers.map((val, i) => {
                                                    const disabled = this.checkAlready(val.size, v.size)
                                                    return <option disabled={disabled} key={i} value={val.size}>{val.size}</option>
                                                })}
                                            </select>
                                            <label className="material-textfield-label">
                                                Container Size<span>*</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="top-margin-10  col-4 p-0">
                                        <div className="form-group material-textfield">
                                            <input
                                                className={`form-control m-10-  pointer-events`}
                                                type="text"
                                                name="defaultWeight"
                                                value={v.defaultWeight ? numberFormatLbs(v.defaultWeight):""}
                                                onChange={(e) => this.editTruckContainer(e, i)}
                                            />
                                            <label className="material-textfield-label">
                                                Default Weight<span>*</span>
                                            </label>
                                        </div>
                                    </div> |
                                    <div className="editable__button  col-4 p-0">
                                        <button className="save_btn editable__btn" disabled={!!!v.defaultWeight} onClick={() => this.updateTruckDetails(truck, true)}>
                                            Save
                                        </button>
                                        <button
                                            className="cancel_btn editable__btn"
                                            onClick={() => this.cancelEdit()}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div> : `${v.size} | ${numberFormatLbs(v.defaultWeight ?? 0)} |`}&nbsp;
                            {editContainerIndex !== i && <span className="edit__text label__text">
                                <button
                                    className="edit_btn editable__btn"
                                    onClick={() => this.setState({ editContainerIndex: i })}
                                >
                                    Edit
                                </button>
                            </span>}
                        </div>)}
                        <div><button
                            className="edit_btn add__container"
                            onClick={() => this.setState({ addContainer: true })}
                        >
                            Add New Container +
                        </button></div>
                    </td>
                    :
                    <td className={this.state.addContainer && "add__new__container"}>
                        <div>
                            <div className="editable__table_field-section">
                                {edit ?
                                    <input
                                        className={`form-control m-10-  pointer-events`}
                                        type="text"
                                        value={truck.defaultOutboundWeight ? numberFormatLbs(truck.defaultOutboundWeight) : ""}
                                        onChange={e => {
                                            this.handleTruckWeightChange(e, i)
                                        }}
                                    /> : numberFormatLbs(truck.defaultOutboundWeight)}&nbsp;|

                                <span className="edit__text label__text">{edit ? (
                                    <>
                                        <div className="editable__button">
                                            <button className="save_btn editable__btn" onClick={() => this.updateTruckDetails(truck)}>
                                                Save
                                            </button>
                                            <button
                                                className="cancel_btn editable__btn"
                                                onClick={() => { this.setState({ edit: false }); this.props.fetchCustomerDetail(this.props.customerId) }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <button
                                        className="edit_btn editable__btn"
                                        onClick={() => this.setState({ edit: true })}
                                    >
                                        Edit
                                    </button>
                                )}</span>
                            </div>
                            <button
                                className="edit_btn add__container"
                                onClick={() => this.setState({ addContainer: true })}
                            >
                                Add New Container +
                            </button>
                        </div>
                    </td>}

            <td className="active__text">
                <div className="switch__button">
                    <Switch
                        checked={truck.status === 3 ? false : true}
                        onChange={this.onTruckStatusChange.bind(this, truck)}
                        className="mr-1"
                    />
                    <span className={truck.status === 1 ? "text-green" : "text-red"}>
                        {truck.status === 1 ? "Active" : "Not Active"}
                    </span>
                </div>
            </td>
            <td>
            <Dropdown 
            trigger={["click"]} 
            menu={{ items:[{
              key: '1',
              label: (
                <div onClick={()=> this.props.editTruck(truck)}>
                    Edit
                </div>
              ),
            }] }} 
            overlayClassName="profile--dropdown--overlay__inpopup"
             placement="bottomRight" 
            >
            <button className="btn ant-dropdown-link three-dot p-0 pr-4">
                  <MoreIcon />
            </button>
            </Dropdown>
            </td>
            {/* <td className={truck.status === 1 ? "text-green" : "text-red"}>
        {truck.status === 1 ? "Active" : "Not Active"}
      </td> */}
        </tr>
    }
}

export default TruckRow
