import React, { PureComponent } from "react";
import "../styles.scss";
import { Dropdown, Menu } from "antd";
import { FilterIcon, MoreIcon, CloseIcon, SyncIcon } from "../../../components/icons";
import moment from "moment-timezone";
import InvoiceDetailComponent from "./invoiceDetail";
import { message } from "antd";
import { Drawer } from "antd";
import _ from "lodash";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CustomerDetailComponent from "../../CustomerDetail/component";
import { Pagination } from "antd";
import InvoicePDF from "./invoicePDF";
import PrintInvoiceModal from "./printInvoice";
import {PrintPaymentRecepit, bottomContent} from "../../../utils/printInvoiceReceipt";
import { paymentMethodPosted, reOrderArray, paymentMethod, customStartCase} from "../../../constants/common";
import EmailAlert from "../../../utils/email_warning_popup";
import { DebounceInput } from "react-debounce-input";
import SearchCheckboxComponent from "../../Common/SearchCheckbox";

// import filterIcon from "../../../images/filter_icon.svg";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default class FinalizedComponent extends PureComponent {
  tableRef = React.createRef();
  constructor(props) {
    const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
    super(props);
    this.state = {
      searchStartsWith: this.props.searchStartsWith,
      toggleMenu: false,
      activeUrl: "",
      needReviedData: [],
      finalizedInvoiceData: [],
      searchText: this.props.searchText || "",
      startDate: moment(props.date.startDate)?.tz(tz)?.toDate(),
      endDate: moment(props.date.endDate)?.tz(tz)?.toDate(),
      isModelOpen: false,
      isPrintRecipt: false,
      isPrintReciptt: false,
      modelData: {},
      modelData2: {},
      companyData: [],
      invoiceDeliveryData: [],
      visible: false,
      filtersData: [],
      batchAction: "",
      invoicesTotal : 0,
      paymentsTotal: 0,
      API_URL:process.env.REACT_APP_API_HOSTNAME,
      selectedCheckBoxData: [],
      spinning: false,
      isCustomerModelOpen: false,
      currentPage: Number(sessionStorage.getItem("finalized-pagination")) ? Number(sessionStorage.getItem("finalized-pagination"))  : 1,
      totalItems: 0,
      limit: 20,
      skip: 0,
      qbDetails: {},
      isQbData: false,
      userData:{},
      defaultUrl: "/finalized/needs-review",
      customerId: "",
      isLoading:true,
      sisterCompanyData:{},
      isSisterCompany:false,
      showModal:false,
      selectedIndex: [],
      singleSync : [],
      syncPosted : false,
      isSyncing: false,
      tz
    };
  }

  // searchTextFilter=()=>{

  // }

  closeModalAlert = () => {
    this.setState({ showModal: false });
  };
  componentDidMount = async () => {
    const searchText = this.props.searchText || "";
    // this.setState({ searchText });
    document.title = "Finalized | Weighworks";

    const sessionFilterData = sessionStorage.getItem('finalized_filtersData');
    // console.log({sessionFilterData})
    if (sessionFilterData) {
      try {
        const filtersDataArray = JSON.parse(sessionFilterData);

        this.setState({
          filtersData: filtersDataArray,
        });
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }

    const {
      value: { user },
    } = await this.props.fetchMe();
    let companyData=[user?.company]
    const limit = await localStorage.getItem("finalizedLimit")
    if(limit){
      this.setState({limit:  Number(limit)})
    }
    this.setState({ companyData: companyData, userData:user })
    if(user?.company){
      const {
        value: {status, data },
      } = await this.props.fetchSetting(user?.company.sisterCompany);
      if(status && data){
      this.setState({sisterCompanyData:data,isSisterCompany:data?.sisterCompany?.length > 0 ? true : false})
      }
    }

    this.getQbDetails()
    // const searchText = ""
    this.fetchFinalizeInvoice(searchText, this.state.startDate, this.state.endDate)
    this.fetchInvoiceDeliveryData();
    const urlarray = window.location.pathname.split("/");
    if (urlarray[4] === "general") {
      this.setState({ isCustomerModelOpen: true, customerId: urlarray[3] });
    }
    if (urlarray[5] === "payment-receipt") {
      this.setState({ isCustomerModelOpen: true, customerId: urlarray[3] });
    }
  };

  fetchInvoiceDeliveryData = async () => {
    const {
      value: { data },
    } = await this.props.fetchInvoiceDeliveryData();
    this.setState({ invoiceDeliveryData: data });
  };

  componentDidUpdate = async () => {
    let startDate = this.props.date.startDate;
    let endDate = this.props.date.endDate;
    if (this.state.startDate !== startDate || this.state.endDate !== endDate) {
      this.setState({ startDate: startDate, endDate: endDate });
      // let searchText = "";
      const { searchText } = this.state;
      this.fetchFinalizeInvoice(searchText, startDate, endDate);
    }
  };

  modelOpen = (index) => {
    let finalizedInvoiceData = this.state.finalizedInvoiceData[index];
    finalizedInvoiceData.index = index;
    finalizedInvoiceData.company = this.state.companyData;
    this.setState({ isModelOpen: true, modelData: finalizedInvoiceData });
  };
  modelOpenNew = async (index) => {
    let finalizedInvoiceData = this.state.finalizedInvoiceData[index];
    finalizedInvoiceData.index = index;
    finalizedInvoiceData.company = this.state.companyData[0];
    const payload = {
      invoiceId: finalizedInvoiceData?.invoiceId,
    };

    const {
      value: { data },
    } = await this.props.getReceivePaymentForPdf(payload);

    const newdata = data.paymentInvoices[0];
    this.setState({
      isPrintReciptt: true,
      modelData: finalizedInvoiceData,
      modelData2: newdata,
    });
  };
  modelOpenPrint = async (index) => {
    let finalizedInvoiceData = this.state.finalizedInvoiceData[index];
    finalizedInvoiceData.index = index;
    finalizedInvoiceData.company = this.state.companyData[0];
    const payload = {
      invoiceId: finalizedInvoiceData?.invoiceId,
    };

    const {
      value: { data },
    } = await this.props.getReceivePaymentForPdf(payload);

    const newdata = data.paymentInvoices[0];
    this.setState({
      isPrintRecipttt: true,
      modelData: finalizedInvoiceData,
      modelData2: newdata,
    });
  };
  closePrintRecipt = () => {
    if (this.props.printByDropDown) {
      this.setState({ isPrintReciptt: false });
      this.setState({ isPrintRecipttt: false });
      this.props.closeModel();
    } else {
      this.setState({ isPrintReciptt: false });
      this.setState({ isPrintRecipttt: false });
    }
  };

  closeModel = () => {
    this.setState({ isModelOpen: false, isPrintRecipt: false });
  };
  updateinvoiceDetailData = (data) => {
    const finalizedInvoiceData = this.state.finalizedInvoiceData;
    finalizedInvoiceData[data.index] = data;
    this.setState({ finalizedInvoiceData: finalizedInvoiceData });
  };

  fetchFinalizeInvoice = async (searchText, startDate, endDate) => {
    // this.setState({ isLoading: true })
    const finalizedInvoices = [];
    const { skip, limit } = this.state;
    const billingDate = JSON.parse(localStorage.getItem('BillingDate')) || {
      startDate: moment().startOf("month").toISOString(),
      endDate: moment().endOf("month").toISOString(),
    };
    const filtersData = JSON.parse(sessionStorage.getItem('finalized_filtersData')) || this.state.filtersData

    const payload = {
      searchText: searchText=== undefined ? this.props.searchText :searchText,
      // startDate: moment(startDate)?.startOf("day")?.toISOString(),
      // endDate: moment(endDate)?.endOf("day")?.toISOString(),
      startDate: moment(billingDate.startDate)?.startOf("day")?.toISOString(),
      endDate: moment(billingDate.endDate)?.endOf("day")?.toISOString(),
      limit: limit,
      skip: skip,
      filterList: filtersData,
      searchStartsWith: this.state.searchStartsWith
    };
    const {
      value: { data },
    } = await this.props.fetchFinalizedInvoice(payload);

    const {
      value: {
        data: {
          paymentTotal,
          invoiceTotal
        }
      }
    } = await this.props.fetchPostedInvoicesCount({...payload, tabtype: "finalized"})
    this.setState({
      invoicesTotal: invoiceTotal,
      paymentsTotal: paymentTotal
    })

    if (data.finalizedInvoice.length > 0) {
      for (let i = 0; i < data.finalizedInvoice.length; i++) {
        let item = data.finalizedInvoice[i];
        let totalOrdersAmount = 0;
      
        for (let j = 0; j < item.order.length; j++) {
          let orderItem = item.order[j];
          totalOrdersAmount += orderItem ? orderItem.totalAmount : 0;
        }
      
        finalizedInvoices.push({
          ...item,
          checkBox: false,
          customAttachments: [],
          showAttachments: [],
          totalOrdersAmount: totalOrdersAmount,
        });
      }
    }
    if (this.tableRef.current) {
      this.tableRef.current.scrollTop = 0;
    }
    this.setState({
      finalizedInvoiceData: finalizedInvoices,
      totalItems: data.totalCount,
      isLoading:false
    });
  };

  numberWithCommas = (x) => {
        x= parseFloat(x)
      var parts = x?.toFixed(2).split(".");
      var num =
        parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
        (parts[1] ? "." + parts[1] : "");
      return num;
    
  };

  phoneFormate = (phone) => {
    return phone
      .replace(/\D+/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  round2decimal = (number) => {
    return Math.round(parseFloat(number) * 100) / 100;
  };
  /*  sendInvoice2 = async (index) => {
    const finalizedInvoiceData = this.state.finalizedInvoiceData[index];
    const companyData = this.state.companyData;
    const payload = {
      company: {
        logo: companyData?.logo,
        businessInfo: companyData?.businessInfo,
        email: companyData?.email,
        domain: companyData?.domain,
        companyId: companyData?._id,
        companyName: companyData?.companyName,
        customerName: finalizedInvoiceData?.customer?.customerName,
        customerId: finalizedInvoiceData?.customer?._id,
      },
      customer: {
        email: finalizedInvoiceData?.customer.email,
        billingEmailAddress: finalizedInvoiceData?.customer?.billingEmailAddress,
        companyName: finalizedInvoiceData?.customer?.companyName,
      },
      invoice: {
        invoiceId: finalizedInvoiceData?.invoiceId,
        dueDate: finalizedInvoiceData?.dueDate,
        total: finalizedInvoiceData?.total,
        balance:
          Number(finalizedInvoiceData?.total) - Number(finalizedInvoiceData.amountPaid),
        amountPaid: finalizedInvoiceData?.amountPaid,
      },
    };
    const {
      value: { status, showMessage },
    } = await this.props.sendInvoiceEmail2(payload);
    if (status) {
      const finalizedInvoiceData = this.state.finalizedInvoiceData;
      finalizedInvoiceData[index].deliveryStatus = true;
      this.setState({
        finalizedInvoiceData: finalizedInvoiceData,
        reRender: !this.state.reRender,
      });
      message.success(showMessage);
    }
  }; */
  afterBulkOptionUse=()=>{
    let finalizedInvoiceData=this.state.finalizedInvoiceData  
    this.setState({ selectedCheckBoxData: [] });
    finalizedInvoiceData.map((item, index) => {
      finalizedInvoiceData[index].checkBox = false;
      return null;
    });
    this.setState({
      finalizedInvoiceData: finalizedInvoiceData,
      reRender: !this.state.reRender,
      batchAction:""
    });
   }

  sendInvoice = async (index) => {
    const finalizedInvoiceData = this.state.finalizedInvoiceData[index];
    const companyData = this.state.companyData[0];
    const billingEmailAddress = this.state?.finalizedInvoiceData?.[index]?.customer?.billingEmailAddress
    if (!billingEmailAddress || billingEmailAddress?.trim() === "") {
      this.setState({ showModal: true });
    }
    else{ 
    const billingEmail = finalizedInvoiceData?.customer?.secondaryBillingContacts ? 
    finalizedInvoiceData?.customer?.secondaryBillingContacts?.map((v)=> v.billingEmailAddress)
    :[]
    const payload = {
      company: {
        logo: companyData?.logo,
        businessInfo: companyData?.businessInfo,
        email: companyData?.email,
        domain: companyData?.domain,
        companyId: companyData?._id,
        companyName: companyData?.companyName,
        customerName: finalizedInvoiceData?.customer?.customerName,
        customerId: finalizedInvoiceData?.customer?._id,
      },
      customer: {
        email: finalizedInvoiceData?.customer.email,
        billingEmailAddress:
          [finalizedInvoiceData?.customer?.billingEmailAddress,...billingEmail],
        companyName: finalizedInvoiceData?.customer?.displayName,
      },
      invoice: {
        invoiceData: finalizedInvoiceData,
        invoiceId: finalizedInvoiceData?.invoiceId,
        dueDate: finalizedInvoiceData?.dueDate,
        total: finalizedInvoiceData?.total,
        balance:
          Number(finalizedInvoiceData?.total) -
          Number(finalizedInvoiceData.amountPaid),
        amountPaid: finalizedInvoiceData?.amountPaid,
      },
    };
    const {
      value: { status, showMessage },
    } = await this.props.sendInvoiceEmail(payload);
    if (status) {
      message.success(showMessage);
    }}
  };
  sendInvoice2 = async (index) => {
    const finalizedInvoiceData = this.state.finalizedInvoiceData[index];
    const payloadforFetchData = {
      invoiceId: finalizedInvoiceData?.invoiceId
    }
    const { value: { data } } = await this.props.getReceivePaymentForPdf(payloadforFetchData);
     const newdata = data.paymentInvoices[0]
    const companyData = this.state.companyData[0];
    const billingEmailAddress = this.state?.finalizedInvoiceData?.[index]?.customer?.billingEmailAddress
    if (!billingEmailAddress || billingEmailAddress?.trim() === "") {
      this.setState({ showModal: true });
    }
    else{
    const billingEmail = finalizedInvoiceData?.customer?.secondaryBillingContacts ? 
    finalizedInvoiceData?.customer?.secondaryBillingContacts?.map((v)=> v.billingEmailAddress)
    :[]
    const payload = {
      company: {
        logo: companyData?.logo,
        businessInfo: companyData?.businessInfo,
        email: companyData?.email,
        domain: companyData?.domain,
        companyId: companyData?._id,
        companyName: companyData?.companyName,
        customerName: finalizedInvoiceData?.customer?.customerName,
        customerId: finalizedInvoiceData?.customer?._id,
      },
      customer: {
        address: finalizedInvoiceData?.customer.address,
        phone: finalizedInvoiceData?.customer.phone,
        city: finalizedInvoiceData?.customer.city,
        zip: finalizedInvoiceData?.customer.zip,
        state: finalizedInvoiceData?.customer.state,
        email: finalizedInvoiceData?.customer.email,
        billingEmailAddress:
          [finalizedInvoiceData?.customer?.billingEmailAddress,...billingEmail],
        companyName: finalizedInvoiceData?.customer?.displayName,
      },
      invoice: {
        invoiceId: finalizedInvoiceData?.invoiceId,
        dueDate: finalizedInvoiceData?.dueDate,
        invoiceData: finalizedInvoiceData,
        total: finalizedInvoiceData?.total,
        balance:
          Number(finalizedInvoiceData?.total) -
          Number(finalizedInvoiceData.amountPaid),
        amountPaid: finalizedInvoiceData?.amountPaid,
      },
      Data: { ...newdata, paymentMethodType:paymentMethod(newdata) },
    };
    const {
      value: { status, showMessage },
    } = await this.props.sendInvoiceEmail2(payload);
    if (status) {
      const finalizedInvoiceData = this.state.finalizedInvoiceData;
      finalizedInvoiceData[index].deliveryStatus = true;
      this.setState({
        finalizedInvoiceData: finalizedInvoiceData,
        reRender: !this.state.reRender,
      });
      message.success(showMessage);
    }}
  };

  reArrangOrdersData = (orderData) => {
    let order = orderData;
    let reArrangOrders = [];
    if (order.length > 0) {
      order.map((item) => {
        if (item?.orderCreationType === "createCharge") {
          if (item?.orderBreakDownItems?.isminimumPrice) {
            let obj = {
              fixedItems: [
                {
                  fixedItem: "Dumping services",
                  unitPrice: item?.orderBreakDownItems?.totalAmountDue,
                  units: 1,
                  totalamout: item?.orderBreakDownItems?.totalAmountDue,
                },
              ],
              ticketNumber: item?.ticketNumber,
              orderCreationType: item?.orderCreationType,
            };
            reArrangOrders.push(obj);
            return null;
          } else {
            if (item?.orderBreakDownItems?.isCapOutPrice) {
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType,
                stripeCardFees:item?.stripeCardFees,
                paymentType:item?.paymentType
              };
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  const fixedItemName = customStartCase(fixedItems?.fixedItem);
                  if (fixedItemName.includes("Yard")) {
                    if (
                      Number(fixedItems?.unitPrice) > Number(item?.orderBreakDownItems?.capoutPrice)
                    ) {
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: item?.orderBreakDownItems?.capoutPrice,
                        units: fixedItems.units,
                        totalamout:
                          item?.orderBreakDownItems?.capoutPrice *
                          fixedItems.units,
                      };
                      obj.fixedItems.push(fixedItemObject);
                    }else{
                      let fixedItemObject = {
                        fixedItem: fixedItems.fixedItem,
                        unitPrice: fixedItems?.unitPrice,
                        units: fixedItems.units,
                        totalamout:
                        fixedItems?.unitPrice *
                          fixedItems.units,
                      };
                      obj.fixedItems.push(fixedItemObject);
                    }
                  } else {
                    let fixedItemObject = {
                      fixedItem: fixedItems.fixedItem,
                      unitPrice: fixedItems?.unitPrice,
                      units: fixedItems.units,
                      totalamout: fixedItems?.unitPrice * fixedItems.units,
                    };
                    obj.fixedItems.push(fixedItemObject);
                  }
                  return null;
                });
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map(
                  (chargeByWeightFixedItems) => {
                    let chargeByWeightItemObject = {
                      fixedItem: chargeByWeightFixedItems?.fixedItem,
                      unitPrice: chargeByWeightFixedItems?.unitPrice,
                      units: chargeByWeightFixedItems?.units,
                      totalamout:
                        chargeByWeightFixedItems?.unitPrice *
                        chargeByWeightFixedItems?.units,
                    };

                    obj.fixedItems.push(chargeByWeightItemObject);
                    return null;
                  }
                );
              }
              reArrangOrders.push(obj);
            } else {
              let obj = {
                fixedItems: [],
                ticketNumber: item?.ticketNumber,
                orderCreationType: item?.orderCreationType,
                stripeCardFees:item?.stripeCardFees,
                paymentType:item?.paymentType
              };
              if (item?.orderBreakDownItems?.fixedItem?.length > 0) {
                item.orderBreakDownItems.fixedItem.map((fixedItems) => {
                  let fixedItemObject = {
                    fixedItem: fixedItems?.fixedItem,
                    unitPrice: fixedItems?.unitPrice,
                    units: fixedItems?.units,
                    totalamout: fixedItems?.unitPrice * fixedItems?.units,
                  };
                  obj.fixedItems.push(fixedItemObject);
                  return null;
                });
              }
              if (item?.orderBreakDownItems?.chargeByWeight?.length > 0) {
                item.orderBreakDownItems.chargeByWeight.map(
                  (chargeByWeightItems) => {
                    let chargeByWeightItemsObject = {
                      fixedItem: chargeByWeightItems?.fixedItem,
                      unitPrice: chargeByWeightItems?.unitPrice,
                      units: chargeByWeightItems?.units,
                      totalamout:
                        chargeByWeightItems?.unitPrice *
                        chargeByWeightItems?.units,
                    };
                    obj.fixedItems.push(chargeByWeightItemsObject);
                    return null;
                  }
                );
              }
              reArrangOrders.push(obj);
            }
          }
        } else {
          let total = 0
          let dumpRate=0
         let isCapOutPrice=(item?.pricingTierCapoutPrice > 0)
         let units = 0
         if (!item?.isMinimumPrice) {
           if (item?.dumpRateType.indexOf("Yard") !== -1) {
             units = Number(item?.yardage)
             if (item?.tonnageDifference < 0) {
               if(isCapOutPrice){
                 dumpRate= Number(item.pricingTierCapoutPrice)
                 total = Number(item?.capoutPrice)
               }else{
                 dumpRate=Number(item?.dumpRate)
                total = Number(item?.yardage) * Number(item?.dumpRate)
               }
             } else{
               dumpRate=Number(item?.dumpRate)
                total = Number(item?.yardage) * Number(item?.dumpRate)
             }
           } else {
             units = Number(item?.tonnage)
             if (item?.tonnageDifference < 0) {
               if(isCapOutPrice){
                 dumpRate=Number(item?.capoutPrice)
                 total = Number(item?.capoutPrice)
               }else{
                 dumpRate=Number(item?.dumpRate)
                 total = Number(item?.tonnage) * Number(item?.dumpRate)
               }
             } else{
               dumpRate=Number(item?.dumpRate)
               total = Number(item?.tonnage) * Number(item?.dumpRate)
             }
           }
           let obj = {
             inboundWeight: item?.inboundWeight,
             outboundWeight: item?.outboundWeight,
             tonnageDifference: item?.tonnageDifference,
             isCapped: item?.isCapped,
             additionalItems: item?.additionalItems,
             // dumpRateType: item?.dumpRateType,
             dumpRate: Number(dumpRate),
             // ticketNumber: item?.ticketNumber,
             netWeight: item?.netWeight,
             // yardage: item?.yardage,
             // tonnage: item?.tonnage,
             // totalAmount: item?.totalAmount,
             paymentType:item?.paymentType,
             stripeCardFees:item?.stripeCardFees,
             totalAmount: total,
             units: units,
             dumpRateType: item?.dumpRateType,
             ticketNumber: item?.ticketNumber,
             isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
             environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
             isFuelSurchardeFee: item?.isFuelSurchardeFee,
             fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
             fuelSurchargeTotal: item?.fuelSurchargeTotal,
             createdAt: item?.createdAt,
           };
           reArrangOrders.push(obj);
         } else {
           let obj = {
             isMinimumPrice: item?.isMinimumPrice,
             inboundWeight: item?.inboundWeight,
             outboundWeight: item?.outboundWeight,
             tonnageDifference: item?.tonnageDifference,
             isCapped: item?.isCapped,
             additionalItems: item?.additionalItems,
             // dumpRateType: item?.dumpRateType,
             dumpRate: Number(item?.totalAmount),
             // ticketNumber: item?.ticketNumber,
             netWeight: item?.netWeight,
             // yardage: item?.yardage,
             // tonnage: item?.tonnage,
             // totalAmount: item?.totalAmount,
             totalAmount: item?.totalAmount,
             units: 1,
             dumpRateType: "Dumping services",
             ticketNumber: item?.ticketNumber,
             isEnvironmentalLoadFee: item?.isEnvironmentalLoadFee,
             environmentalLoadFeeAmount: item?.environmentalLoadFeeAmount,
             isFuelSurchardeFee: item?.isFuelSurchardeFee,
             fuelSurchardeFeeAmount: item?.fuelSurchardeFeeAmount,
             fuelSurchargeTotal: item?.fuelSurchargeTotal,
             createdAt: item?.createdAt,
           };
           reArrangOrders.push(obj);
         }
        }
        return null;
      });
    }
    // this.setState({reArrangOrders:reArrangOrders,reRender: !this.state.reRender})
    return reArrangOrders;
  };
  bulkOptions = async (event) => {
    this.setState({ batchAction: event.target.value });
    const emailPromise = [];
    const finalizedInvoiceData = this.state.finalizedInvoiceData;
    const companyData = this.state.companyData[0];
    if (event.target.value === "send") {
      this.setState({ spinning: true });
      const filtered_data = finalizedInvoiceData?.filter(
        (item) => item?.customer?.billingEmailAddress?.length > 0 && item?.checkBox
      );
    
      finalizedInvoiceData.map(async(item) => {
        if (item.checkBox) {
          let newdata;
          if(item.isRecivePaymentInvoice){
            const payload = {
              invoiceId: item?.invoiceId,
            };
            const {
              value: { data },
            } = await this.props.getReceivePaymentForPdf(payload);
            newdata = data.paymentInvoices[0];
          }
          if (filtered_data.length <= 0) {
            this.setState({ showModal: true });
        }
          else{
          const billingEmail = item?.customer?.secondaryBillingContacts ? 
          item?.customer?.secondaryBillingContacts.map((v)=> v.billingEmailAddress)
          :[]
          const payload = {
            company: {
              logo: companyData?.logo,
              businessInfo: companyData?.businessInfo,
              email: companyData?.email,
              domain: companyData?.domain,
              companyId: companyData?._id,
              companyName: companyData?.companyName,
              customerName: item?.customer?.customerName,
              customerId: item?.customer?._id,
            },
            customer: {
              email :item.customer.email,
              city : item.customer.city,
              state :item.customer.city,
              phone: item.customer.phone,
              zip :item.customer.zip,
              billingEmailAddress :[item?.customer?.billingEmailAddress,...billingEmail],
             companyName:item?.customer?.displayName,
              customerName : item?.customer?.customerName,
             address : item?.customer?.address,
            },
            invoice: {
              invoiceData: item,
              invoiceId: item?.invoiceId,
              dueDate: item?.dueDate,
              total: item?.total,
              balance: item?.total - item?.amountPaid,
              amountPaid: item?.amountPaid,
            },
            Data: { ...newdata, paymentMethodType:paymentMethod(newdata) },
          };
          if(item.isRecivePaymentInvoice){
            emailPromise.push(this.props.sendInvoiceEmail2(payload));
          }
          else{
            emailPromise.push(this.props.sendInvoiceEmail(payload));
          }
          // const {
          //   value: { status },
          // } = this.props.sendInvoiceEmail(payload);
          // if (status) {
          //   this.setState({ bulkInvoiceEmailPleaseWait: false })
          // }
        }
        return null;
      } });
      const sendEmailResponce = await Promise.all(emailPromise);
      if (sendEmailResponce) {
        this.setState({ spinning: false });
        this.afterBulkOptionUse()
      }
    } else if (event.target.value === "print") {
      this.setState({ spinning: true });
      let printData = [];
      //  = postedOrderData.filter((item) => item.checkBox === true);
      for(const item of finalizedInvoiceData){
        if (item.checkBox === true) {
          printData.push({ ...item, company: this.state.companyData[0]});
        }
      }
      const orderedArray = reOrderArray(printData);
      const {value} = await this.props.batchPrintInvoice({
        invoicelist: orderedArray,
      });
      if (value) {
        const url = URL.createObjectURL(value);
        const printWindow = window.open(url, '_blank');
          printWindow.onload = function() {
            printWindow.print();
          };
        
        this.setState({
          finalizedInvoiceData: finalizedInvoiceData,
          reRender: !this.state.reRender,
          selectedCheckBoxData: [],
          spinning: false,
        });
        this.afterBulkOptionUse()
      }
     /*  let printWindow = "";
      const printData = finalizedInvoiceData.filter(
        (item) => item.checkBox === true
      );
      this.setState({ spinning: true });
       const invoice = [];
       let currentLength = 0;
       let totalLength = printData.length;
      const printPromises = printData.map((items, i) => {
        return new Promise(async (resolve, reject) => {
            let { order } = items;
            const ordersData = this.reArrangOrdersData(sort_by_descending(order, "createdAt"));
            totalLength+=ordersData.length;
            items.company=this.state.companyData;
            let body1 = '';
            if(items.isRecivePaymentInvoice){
              const payload = {
                invoiceId: items?.invoiceId,
              };
              const {
                value: { data },
              } = await this.props.getReceivePaymentForPdf(payload);
              invoice.push(data.paymentInvoices[0])
              const invoices = invoice.filter(e => e.invoiceId === items.invoiceId )
              const newdata = data.paymentInvoices[0];
              const company = items.company[0];
              currentLength+=1;
              const isLastPromise = currentLength === totalLength
              body1 = PrintPaymentRecepit(items,newdata,company,invoices,isLastPromise)
             }
             else{
              currentLength+=1;
              if(this.state.isSisterCompany){
                body1 = PrintSisterCompanyInvoiceReceipt(items,this.state.sisterCompanyData)
              }else{
                body1 = PrintInvoiceReceipt(items)
              }
              
             }
            for (let i = 0; i < ordersData.length; i++) {
              let ordr = ordersData[i];
              const { value } = await this.props.fetchOrder(ordr.ticketNumber);
              order = value.data;
              currentLength+=1; 
              const isLastPromise = currentLength === totalLength
              const last = ordersData.length-1 === i && isLastPromise
              if(this.state.isSisterCompany){
                body1 += PrintSisterCompanyOrderReceipt(value,last)
              }else{
                body1 += PrintOrderReceipt(value,last)
              }
             
            }
            printWindow += body1 ? body1 :"";
            resolve()
        });
      });
      if(this.state.isSisterCompany){
        printWindow += bottomSisterCompanyContent();
      }else{
        printWindow += bottomContent();
      }
    
      Promise.all(printPromises).then(() => {
        finalizedInvoiceData.forEach((item) => {
          item.checkBox = false;
        });
      }).finally(()=>{
        let printInvoice = window.open("invoice", "_blank");
        if(printInvoice){
        printInvoice.document.write(printWindow);
        printInvoice.document.close();
        printInvoice.onload = function () {
        printInvoice.print();
        };
      }
    })
      this.setState({
        finalizedInvoiceData: finalizedInvoiceData,
        reRender: !this.state.reRender,
        selectedCheckBoxData: [],
        spinning: false,
      });
      this.afterBulkOptionUse() */
    } else if (event.target.value === "download") {
      this.setState({ spinning: true });
      let printData = [];
      //  = finalizedInvoiceData.filter((item) => item.checkBox === true);
      for(const item of finalizedInvoiceData){
        if (item.checkBox === true) {
         printData.push({ ...item, company: this.state.companyData[0] });
       }
      }
      const {
        value: { data },
      } = await this.props.downloadBulkInvoice({
        invoicelist: printData,
      });
      finalizedInvoiceData.map((item, index) => {
        finalizedInvoiceData[index].checkBox = false;
        return null;
      });
      this.setState({
        finalizedInvoiceData: finalizedInvoiceData,
        reRender: !this.state.reRender,
        selectedCheckBoxData: [],
        spinning: false,
      });
      window.open(data);
      this.afterBulkOptionUse()
    }else if (event.target.value === "syncwithqb") {
      this.setState({ spinning: true });
      const syncData = finalizedInvoiceData.filter((item) => item.checkBox === true);
      const indexData = [];
      for (const item of syncData) {
        indexData.push(item.invoiceId);
      }
      this.setState({
        isSyncing: true,
        selectedIndex: [...this.state.selectedIndex, ...indexData],
      });
      const payload = {
        order: syncData,
      };
      await this.props.syncBulkPostedOrder(payload);
      this.fetchFinalizeInvoice(
        this.state.searchText,
        this.state.startDate,
        this.state.endDate
      );
      this.setState({
        isSyncing: false,
        selectedIndex: [],
      });
      this.setState({ spinning: false });
      this.afterBulkOptionUse()
    } else if (event.target.value === "reminderInvoice") {
      this.setState({ spinning: true });
      const filtered_data = finalizedInvoiceData?.filter(
        (item) => item?.customer?.billingEmailAddress?.length > 0 && item?.checkBox
      );
      const companyData = this.state.companyData[0];
      finalizedInvoiceData.map((item) => {
        if (item.checkBox && !item?.isRecivePaymentInvoice) {
          this.setState({ bulkInvoiceEmailPleaseWait: true });
          if (filtered_data.length <= 0) {
            this.setState({ showModal: true });
        }
    else{
          const billingEmail = item?.customer?.secondaryBillingContacts ? 
          item?.customer?.secondaryBillingContacts.map((v)=> v.billingEmailAddress)
          :[]
          const payload = {
            company: {
              logo: companyData?.logo,
              businessInfo: companyData?.businessInfo,
              email: companyData?.email,
              domain: companyData?.domain,
              companyId: companyData?._id,
              companyName: companyData?.companyName,
              customerName: item?.customer?.customerName,
              customerId: item?.customer?._id,
            },
            customer: {
              email: item?.customer.email,
              billingEmailAddress: [item?.customer?.billingEmailAddress,...billingEmail],
              companyName: item?.customer?.displayName,
            },
            invoice: {
              invoiceData: item,
              invoiceId: item?.invoiceId,
              dueDate: item?.dueDate,
              total: item?.total,
              balance: item?.total - item?.amountPaid,
              amountPaid: item?.amountPaid,
            },
          };
          emailPromise.push(this.props.sendReminderInvoiceEmail(payload));
        }}
        return null;
      });
      const sendEmailResponce = await Promise.all(emailPromise);
      if (sendEmailResponce) {
        this.setState({ spinning: false });
        this.afterBulkOptionUse()
      }
    } else if(event.target.value === "posted"){
      this.setState({ spinning: true });
      const postedData = finalizedInvoiceData.filter(
        (item) => item.checkBox === true
      );
      const {
        value: { status },
      } = await this.props.movedInvoiceBulkAction({
        data:postedData, updateType: "post", billingStatus:"posted"
      });
      if(status){
        this.setState({ spinning: false });
        this.afterBulkOptionUse()
        this.fetchFinalizeInvoice(
          this.state.searchText,
          this.state.startDate,
          this.state.endDate
        );
      }
    }
  };

  assignUrl = (url) => {
    let customerId = "";
    const path = url.split("/");
    if (path[1] !== "order-details" && this.state.customerId === "") {
      customerId = path[2];
    } else {
      customerId = this.state.customerId;
    }

    if (path[1] === "order-details" || path[1] === "edit-customer") {
      if (path[1] === "order-details") {
        this.props.navigate(url);
        this.setState({ isCustomerModelOpen: false });
      }
      if (path[1] === "edit-customer") {
        this.props.navigate({
          pathname: "/edit-customer",
          state: {
            id: customerId,
          },
        });
      }
    } else {
      if (path[3] === "general") {
        this.props.navigate(`/finalized/customer-detail/${customerId}/general`);
      }
      if (path[3] === "billing" && path[4] === "invoice") {
        this.props.navigate(
          `/finalized/customer-detail/${customerId}/billing/invoice`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "billing" && path[4] === "payment-receipt") {
        this.props.navigate(
          `/finalized/customer-detail/${customerId}/billing/payment-receipt`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "billing" && path[4] === "credit-memo") {
        this.props.navigate(
          `/finalized/customer-detail/${customerId}/billing/credit-memo`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "past-weigh-ins") {
        this.props.navigate(
          `/finalized/customer-detail/${customerId}/past-weigh-ins`
        );
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "rates") {
        this.props.navigate(`/finalized/customer-detail/${customerId}/rates`);
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "drivers") {
        this.props.navigate(`/finalized/customer-detail/${customerId}/drivers`);
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "trucks") {
        this.props.navigate(`/finalized/customer-detail/${customerId}/trucks`);
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "payment-methods") {
        this.props.navigate(`/finalized/customer-detail/${customerId}/payment-methods`);
        this.setState({ isCustomerModelOpen: true });
      }      
      if (path[3] === "projects") {
        this.props.navigate(`/finalized/customer-detail/${customerId}/projects`);
        this.setState({ isCustomerModelOpen: true });
      }
      if (path[3] === "history") {
        this.props.navigate(`/finalized/customer-detail/${customerId}/history`);
        this.setState({ isCustomerModelOpen: true });
      }
    }
  };

  openBillingReciept = async (data) => {
    Object.assign(data, { company: this.state.companyData });
    const orderedArray = reOrderArray([data]);
    const {value} = await this.props.batchPrintInvoice({
      invoicelist: orderedArray,
    });
    if (value) {
      const url = URL.createObjectURL(value);
      const printWindow = window.open(url, '_blank');
        printWindow.onload = function() {
          printWindow.print();
        };
      }
    /* let printWindow = "";
    let printData = [data];
    let body1 = ""
    if(this.state.isSisterCompany){
      body1 += bottomSisterCompanyContent();
    }else{
      body1 += bottomContent();
    }
   
    const printPromises = printData.map(async (items, i) => {
      return new Promise(async (resolve, reject) => {
        let { order } = items;
        const orderData = sort_by_descending(this.reArrangOrdersData(order), "createdAt");
        items.company=this.state.companyData
        if(this.state.isSisterCompany){
          body1 += PrintSisterCompanyInvoiceReceipt(items,this.state.sisterCompanyData)
        }else{
          body1 += PrintInvoiceReceipt(items)
        }
        for (let i = 0; i < orderData.length; i++) {
          let ordr = orderData[i];
          const { value } = await this.props.fetchOrder(ordr.ticketNumber);
          order = value.data;
          const last = i === orderData.length - 1;
          if(this.state.isSisterCompany){
            body1 += PrintSisterCompanyOrderReceipt(value,last)
          }else{
            body1 += PrintOrderReceipt(value,last)
          }
     
        }
        printWindow += body1;
        resolve()
      });
    });

    Promise.all(printPromises).then(() => {}).finally(()=>{
      let printInvoice = window.open("invoice", "_blank");
      if(printInvoice){
      printInvoice.document.write(printWindow);
      printInvoice.document.close();
      printInvoice.onload = function () {
        printInvoice.print();
      };
    }
  }) */
  };

  closeCustomerDetatilModel = () => {
    this.setState({ isCustomerModelOpen: false });
    this.props.navigate("/billing/finalized-transactions");
  };

  openBillingRecieptForPR = async (index, isView=false) => {
    let printWindow = "";
    let postedOrderData = this.state.finalizedInvoiceData[index];
    postedOrderData.index = index;
    postedOrderData.company = this.state.companyDataWithIndex;
    const payload = {
      invoiceId: postedOrderData?.invoiceId,
    };
    const {
      value: { data },
    } = await this.props.getReceivePaymentForPdf(payload);
    const newdata = data.paymentInvoices[0];
    const company = this.state.companyData[0];
    Object.assign(company, { sisterCompanydetails: this.state.isSisterCompany ? this.state.sisterCompanyData :undefined });

    const invoices = newdata && newdata.invoices;
    // const customer = postedOrderData && postedOrderData.customer;
    // const creditAmount =
    //   invoices &&
    //   invoices?.map((item) => (item.payment !== null ? item.creditAmount : 0));
    // const result =
    //   creditAmount &&
    //   creditAmount.reduce(
    //     (accumulator, currentValue) => accumulator + currentValue,
    //     0
    //   );
    let body1 = ""
        body1 += bottomContent();
    const printPromises = [postedOrderData].map((items, i) => {
      return new Promise((resolve, reject) => {
        body1 += PrintPaymentRecepit(items,newdata,company,invoices,true,isView)
        printWindow += body1;
        resolve()
      });
    });

    if (!isView) {
      const {value} = await this.props.printPaymentReciept({html:printWindow,ticketNumber:postedOrderData.invoiceId})
  if (value) {
    const url = URL.createObjectURL(value);
    const printWindow = window.open(url, '_blank');
      printWindow.onload = function() {
        printWindow.print();
      };
    }
    } else {
      Promise.all(printPromises).then(() => {}).finally(()=>{
        let printInvoice = window.open(`invoice`, "_blank");
        if(printInvoice){
        printInvoice.document.write(printWindow);
        printInvoice.document.close();
        printInvoice.onload = function () {
          if(!isView){
            printInvoice.print();
          }
        };
      }
    }) 
    }
  };

  menu = (index) => {
    const invoiceType = this.state.finalizedInvoiceData[index]?.invoiceType;
    const invoiceId = this.state.finalizedInvoiceData[index]?.invoiceId;
    return (
      <>
      <Menu>
        {invoiceType === "payment" && invoiceId.startsWith("PR") === true ? (
          <>
            <Menu.Item
              key="0"
              onClick={() => {
                // this.modelOpenNew(index);
                this.openBillingRecieptForPR(index, true);
              }}
            >
              View
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                this.sendInvoice2(index);
              }}
              key="1"
            >
              Send
            </Menu.Item>
            <Menu.Item
              // onClick={() => {
                // this.setState({ isPrintReciptnew: true }, () => {
                //   this.modelOpenPrint(index);
                // });
                
              // }}
              onClick={() => {
                this.openBillingRecieptForPR(index);
              }}
              key="2"
            >
              Print
            </Menu.Item>
            {/* <Menu.Item key="6">
        Needs Review 
        </Menu.Item> */}
          </>):(
          <><Menu.Item onClick={() => { this.modelOpen(index) }} key="0"  >
          View
        </Menu.Item>
        <Menu.Item onClick={() => { this.sendInvoice(index) }} key="1">
          Send
        </Menu.Item>
        <Menu.Item onClick={() => 
          this.openBillingReciept(this.state.finalizedInvoiceData[index])
          // this.props.navigate(`/billing-receipt/${this.state.finalizedInvoiceData[index].invoiceId}`)
          // { this.setState({ isPrintRecipt: true }, () => { this.modelOpen(index) }) }
          } key="2">
          Print
        </Menu.Item></>)}
      </Menu>
      {this.state.showModal && <EmailAlert
      isClose={this.state?.showModal}
       showEmailAlert={this.state.showModal}
        onCloseAlert={()=> {
          this.closeModalAlert()
          }}
         />}
      </>
    );
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  openFilterList = async (value) => {
    this.setState({ currentPage: 1,skip: 0})
    const filtersData = this.state.filtersData.slice();
    const submenu = ["cash", "check", "credit-card", "credit-memo", "wire","terminal"];
    const paymentCheckbox = "payment";
    if (value === paymentCheckbox) {
      if (!filtersData.includes(value)) {
        filtersData.push(value);
        filtersData.push(...submenu);
      } else {
        let index = filtersData.indexOf(value);
        if (index !== -1) {
          filtersData.splice(index, 1);
        }
        submenu.forEach(submenuItem => {
          var submenuIndex = filtersData.indexOf(submenuItem);
          if (submenuIndex !== -1) {
            filtersData.splice(submenuIndex, 1);
          }
        });
      }
    } else {
      if (filtersData.includes(value)) {
        let index = filtersData.indexOf(value);
        if (index !== -1) {
          filtersData.splice(index, 1);
        }else {
          filtersData.push(value);
        }
        const submenuSelected = submenu.some(item => filtersData.includes(item));
        if (!submenuSelected && filtersData.includes(paymentCheckbox)) {
          var paymentIndex = filtersData.indexOf(paymentCheckbox);
          filtersData.splice(paymentIndex, 1);
        }
      } else {
        filtersData.push(value);
        if (!filtersData.includes(paymentCheckbox) && submenu.includes(value)) {
          filtersData.push(paymentCheckbox);
        }
      }
      this.setState(
        { currentPage: 1, skip: 0, selectedCheckBoxData: [] },
        () => {
          this.applyFiltersAndFetchData();
        }
      );
    }
    this.setState(
      { filtersData: filtersData, reRender: !this.state.reRender },
      () => {
        sessionStorage.setItem('finalized_filtersData', JSON.stringify(this.state.filtersData))
        this.fetchFinalizeInvoice(
          this.state.searchText,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  applyFiltersAndFetchData = () => {
    this.setState(
      { reRender: !this.state.reRender },
      () => {
        sessionStorage.setItem('finalized-pagination', JSON.stringify(this.state.filtersData))
      }
    );
};

    onChangePage = async (page) => {
    this.setState(
      { currentPage: page, skip: (page - 1) * this.state.limit, selectedCheckBoxData: [] },
      () => {
        this.fetchFinalizeInvoice();
      }
    );
    await sessionStorage.setItem("finalized-pagination", page)
  };

  selectedCheckBox = (data, type, index) => {
    const { selectedCheckBoxData, finalizedInvoiceData } = this.state;
    const selectedIndex = [...this.state.selectedIndex];
    const selectedIndexPos = selectedIndex.indexOf(index);
    if (selectedIndexPos > -1) {
      selectedIndex.splice(selectedIndexPos, 1);
    } else {
      selectedIndex.push(index);
    }
    this.setState((prevState) => ({ 
      selectedIndex : selectedIndex , 
      singleSync: prevState.singleSync.filter(i => i !== index),}));
    if (type === "all") {
      let allIndex = finalizedInvoiceData.map((item, index) => index);
      allIndex = allIndex.filter(index => 
        !(this.state.selectedIndex.includes(index) ));
      this.setState({selectedIndex : allIndex , 
      })
      if (
        this.state.finalizedInvoiceData.length ===
        this.state.selectedCheckBoxData.length
      ) {
        this.setState({ selectedCheckBoxData: [] });
        finalizedInvoiceData.map((item, index) => {
          finalizedInvoiceData[index].checkBox = false;
          return null;
        });
        this.setState({
          finalizedInvoiceData: finalizedInvoiceData,
          reRender: !this.state.reRender,
        });
      } else {
        finalizedInvoiceData.map((item, index) => {
          finalizedInvoiceData[index].checkBox = true;
          if (!selectedCheckBoxData.includes(item._id))
            selectedCheckBoxData.push(item._id);
          return null;
        });
        this.setState({
          selectedCheckBoxData: selectedCheckBoxData,
          reRender: !this.state.reRender,
        });
      }
    } else {
      const arrayIndex = selectedCheckBoxData.indexOf(data._id);
      if (selectedCheckBoxData.includes(data._id)) {
        finalizedInvoiceData[index].checkBox = data.checkBox ? false : true;
        selectedCheckBoxData.splice(arrayIndex, 1);
      } else {
        finalizedInvoiceData[index].checkBox = data.checkBox ? false : true;
        selectedCheckBoxData.push(data._id);
      }
      this.setState({
        finalizedInvoiceData: finalizedInvoiceData,
        selectedCheckBoxData: selectedCheckBoxData,
        reRender: !this.state.reRender,
      });
    }
  };
  changeLimit = async (e)=>{
    this.setState({limit: e.target.value === "results" ? 20 : Number(e.target.value)},
    () => {
      this.fetchFinalizeInvoice(
        this.state.searchText,
        this.state.startDate,
        this.state.endDate
        );
      }
      )
      await localStorage.setItem("finalizedLimit", e.target.value === "results" ? "20" : e.target.value)

  }

  getQbDetails = async() => {
    const { value } = await this.props.getQbDetails()
    if(value?.status){
      this.setState({ isQbData: true, qbDetails: value.data })
    }
  }
  syncFinalizeOrder = async (index) => {
    this.setState({selectedCheckBoxData: [] })
    if(!this.state.finalizedInvoiceData[index]?.customer?.qbCustomerId){
      message.error("Customer doesn't have Quickbooks mapping");
    }else{
      this.setState ((prevState) => ({
        isSyncing: true,
        syncPosted : true,
        selectedIndex: prevState.selectedIndex.filter(i => i !== index),
        singleSync : [...this.state.singleSync,index],
      }));
      const invoice = this.state.finalizedInvoiceData[index]
      const invoiceId = invoice.isRecivePaymentInvoicesId[0];
      const foundIndex = this.state.finalizedInvoiceData.findIndex(item => item.invoiceId === invoiceId);
      if (foundIndex !== -1 && !this.state.singleSync.includes(foundIndex)) {
        this.setState((prevState) => ({
          singleSync: [...prevState.singleSync, foundIndex]
        }));
      }
      const payload = {
        order: this.state.finalizedInvoiceData[index],
      };
      await this.props.syncPostedOrder(payload);
      this.fetchFinalizeInvoice(
        this.state.searchText,
        this.state.startDate,
        this.state.endDate
      );
      this.setState((prevState) => ({
        isSyncing: false,
        selectedIndex: [],
        singleSync: prevState.singleSync.filter(i => i !== index && i !== foundIndex),
        syncPosted: false
      }));
    }
  };

  render() {
    const { startDate, endDate, searchText, userData, finalizedInvoiceData } = this.state;
    return (
      <div className="posted__wrapper">
        <section className="table__filter-section">
        <>
          <div className="table__filter-inner">
            <div className="filter-form">
              <DebounceInput
                type="text"
                value={this.state.searchText.trimStart()}
                onChange={(e) => {
                  this.props.handleSearchText(e.target.value.trimStart(), this.state.searchStartsWith)
                  this.setState({
                    searchText: e.target.value.trimStart(),
                    totalItems: 0,
                    currentPage:1,
                    skip:0,
                    isLoading: this.state.searchText.trimStart()!=="" && finalizedInvoiceData.length === 0
                  }, () => {
                    const func = () => {
                      (this.state.searchText.length === 0 || e?.target?.value?.length === 0 || e?.target?.value?.trim().length) && this.fetchFinalizeInvoice(
                        this.state.searchText?.trim(),
                        this.state.startDate,
                        this.state.endDate,
                        10
                      )
                    }
                    func()
                  });
                }}
                placeholder="Search by company name or invoice #... "
                className="form-control"
                minLength={2}
                debounceTimeout={1000}
              />
              <button
                onClick={() => {
                  this.setState({ visible: true });
                }}
                className="btn btn-dark btn-sm btn-outline-black filter-btn"
              >
                {/* <img className="icon" src={filterIcon} alt="" /> */}
                <FilterIcon />
                Filter{" "}
                {this.state.filtersData.length > 0 &&
                this.state.filtersData.filter(item => item !== "payment").length}
              </button>
            </div>
            
            <div className="d-flex align-items-center w-auto">
              <div className="custom-select-spiner ml-2">
                {this.state.spinning && (
                  <Spin indicator={antIcon} spinning={true} />
                )}
                <select
                  className="form-control custom-select material-textfield-input batch-action-btn"
                  name="dateType"
                  required
                  value={this.state.batchAction}
                  onChange={(e) => {
                    this.bulkOptions(e);
                  }}
                  disabled={!this.state.selectedCheckBoxData.length > 0}
                >
                  {!this.state.spinning && (
                    <>
                      <option value="">Batch Action</option>
                      <option value="send">Send</option>
                      <option value="print">Print</option>
                      <option value="download">Download</option>
                      <option value="syncwithqb">Sync with Quickbooks</option>
                      {
                        !this.state.finalizedInvoiceData.filter(e=> e.checkBox === true).some(e => e.invoiceType === "payment") && <option value="reminderInvoice">Reminder invoices</option>
                      }
                      <option value="posted">Move back to Posted</option>
                    </>
                  )}
                </select>
              </div>
              <select
                className="form-control custom-select material-textfield-input batch-action-btn result-select ml-2"
                value={this.state.limit.toString()}
                onChange={(e) => {
                  this.changeLimit(e)
                }}
              >
                    <option value="results">Results</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
              </select>
            </div>
          </div>
          {this.state.showModal && <EmailAlert
      isClose={this.state?.showModal}
       showEmailAlert={this.state.showModal}
        onCloseAlert={()=> {
          this.closeModalAlert()
          }}
         />}
          </>
        </section>
        <SearchCheckboxComponent
          searchStartsWith={this.state.searchStartsWith}
          handleSearchStartsWith={(value) => this.setState({ searchStartsWith: value }, () => {
            this.state.searchText!== "" && this.fetchFinalizeInvoice( this.state.searchText?.trim(), this.state.startDate, this.state.endDate, 10)
            this.props.handleSearchText(this.state.searchText, this.state.searchStartsWith)
          }
          )}
        />
        <section className="table__section">
          <div className="card">
            <div ref={this.tableRef} className="table-responsive">
        
              <table className="table custom-table fixTableHead">
                <thead>
                  <tr>
                    <th className="first-child">
                      <label className="custom-checkbox custom-checkbox-line radio__checkbox">
                        Customer
                        <input
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={
                            this.state.selectedCheckBoxData.length > 0 &&
                            this.state.finalizedInvoiceData.length ===
                              this.state.selectedCheckBoxData.length
                          }
                          onChange={() => {
                            this.selectedCheckBox(
                              this.state.finalizedInvoiceData,
                              "all"
                            );
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </th>
                    <th>Invoice #</th>
                    <th>QB Sync</th>
                    <th>Invoice Date</th>
                    <th>Due Date</th>
                    <th>Type</th>
                    <th>Method</th>
                    <th>Total</th>
                    <th>Balance</th>
                    <th className="pr-40">Status</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {!this.state.isLoading ?
                    this.state.finalizedInvoiceData.length > 0 ? (
                      this.state.finalizedInvoiceData.slice(0, this.state.limit).map((item, index) => (
                        <tr>
                          <td className="first-child underline">
                              <label className="custom-checkbox custom-checkbox-line radio__checkbox underline">
                                <input
                                  checked={item.checkBox}
                                  onChange={() => {
                                    this.selectedCheckBox(
                                      item,
                                      "single",
                                      index
                                    );
                                  }}
                                  type="checkbox"
                                  className="custom-checkbox--input"
                                />
                                <span className="checkmark"></span>
                              </label>
                        <span onClick={() => {
                           sessionStorage.removeItem('need_review_filtersData')
                           sessionStorage.removeItem('posted_filtersData')
                           sessionStorage.removeItem('finalized_filtersData')
                          this.setState({ isCustomerModelOpen: true, customerId: item?.customer?.customerId })
                          this.props.navigate(`/finalized/customer-detail/${item?.customer?.customerId}/general`)
                        }}>
                          {item?.customer?.displayName !== "" ? item?.customer?.displayName : "N/A"}</span>
                      </td>
                      <td onClick={() => {
                        if (item?.isRecivePaymentInvoice) {
                          sessionStorage.removeItem('need_review_filtersData')
                          sessionStorage.removeItem('posted_filtersData')
                          sessionStorage.removeItem('finalized_filtersData')
                          this.setState({
                            isCustomerModelOpen: true,
                            customerId: item?.customer?.customerId,
                          });
                          this.props.navigate(
                            `/finalized/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                          );
                        } else {
                          this.modelOpen(index)
                        }
                      }} className="underline">{item?.invoiceId}</td>
                      {!this.state.isQbData 
                        ?
                        <td className={userData?.permissions?.permissionOptions && userData?.permissions?.permissionOptions?.syncToQuickbooks === true ? "":"disable-sync"}>
                          <button className="btn btn-dark setupqb" onClick={()=>{
                            this.props.navigate(
                              `/integrations`
                            );
                          }}>Setup QB</button>
                        </td>
                        :
                        ((item.isRecivePaymentInvoice) && userData?.permissions?.permissionOptions && userData?.permissions?.permissionOptions?.syncToQuickbooks === true) ? (
                          <td>
                            {this.state.isSyncing &&
                            // this.state.selectedIndex?.find(
                            //   (e) => e === index || e === item?.invoiceId
                            // )
                            ((this.state.singleSync.includes(index) && this.state.syncPosted === true) || (this.state.selectedIndex.includes(index) && this.state.syncPosted === false ))
                             ? (
                              <Spin
                                size="small"
                                indicator={
                                  <LoadingOutlined
                                    style={{ fontSize: 20, color: "#000" }}
                                    spin
                                  />
                                }
                              />
                            ) : _.get(item, "isSynced", false) ? (
                              <SyncIcon
                                // onClick={() => this.syncPostedOrder(index)}
                                className="icon btn-icon green"
                              />
                            ) : (
                              <SyncIcon
                                onClick={() => this.syncFinalizeOrder(index)}
                                className="icon btn-icon red"
                              />
                            )}
                          </td>
                        ) : (!(item.isRecivePaymentInvoice) && userData?.permissions?.permissionOptions && userData?.permissions?.permissionOptions?.syncToQuickbooks === true) ? (
                          <td className={userData?.permissions?.permissionOptions && userData?.permissions?.permissionOptions?.syncToQuickbooks === true ? "":"disable-sync"}>
                            { 
                              this.state.isSyncing &&
                              ((this.state.singleSync.includes(index) && this.state.syncPosted === true) || (this.state.selectedIndex.includes(index) && this.state.syncPosted === false))
                              ? (
                                <>
                                <Spin
                                        size="small"
                                        indicator={<LoadingOutlined
                                          style={{ fontSize: 20, color: "#000" }}
                                          spin />} /></>
                              ) : (! item?.qbInvoiceId) ? (
                                <SyncIcon
                                  onClick={() => this.syncFinalizeOrder(index)}
                                  className="icon btn-icon red"
                                />
                              )
                             :  (
                              <SyncIcon
                                // onClick={() => this.syncPostedOrder(index)}
                                className="icon btn-icon green"
                              />
                            )}
                          </td>
                        ) : (
                          <td className={userData?.permissions?.permissionOptions && userData?.permissions?.permissionOptions?.syncToQuickbooks === true ? "":"disable-sync"}>-</td>
                        )}
                      <td className='cursor-pointer' onClick={() => { if (item?.isRecivePaymentInvoice) {
                         sessionStorage.removeItem('need_review_filtersData')
                         sessionStorage.removeItem('posted_filtersData')
                         sessionStorage.removeItem('finalized_filtersData')
                          this.setState({
                            isCustomerModelOpen: true,
                            customerId: item?.customer?.customerId,
                          });
                          this.props.navigate(
                            `/finalized/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                          );
                        } else {
                          this.modelOpen(index)
                        } }} >{moment(item?.invoiceDate)?.tz(this.state?.tz)?.format('L')}</td>
                      <td className='cursor-pointer' onClick={() => { if (item?.isRecivePaymentInvoice) {
                         sessionStorage.removeItem('need_review_filtersData')
                         sessionStorage.removeItem('posted_filtersData')
                         sessionStorage.removeItem('finalized_filtersData')
                          this.setState({
                            isCustomerModelOpen: true,
                            customerId: item?.customer?.customerId,
                          });
                          this.props.navigate(
                            `/finalized/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                          );
                        } else {
                          this.modelOpen(index)
                        } }} >{item?.dueDate ?  moment(item?.dueDate)?.tz(this.state?.tz)?.format('L') : "-"}</td>
                      <td className='cursor-pointer' onClick={() => { if (item?.isRecivePaymentInvoice) {
                         sessionStorage.removeItem('need_review_filtersData')
                         sessionStorage.removeItem('posted_filtersData')
                         sessionStorage.removeItem('finalized_filtersData')
                          this.setState({
                            isCustomerModelOpen: true,
                            customerId: item?.customer?.customerId,
                          });
                          this.props.navigate(
                            `/finalized/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                          );
                        } else {
                          this.modelOpen(index)
                        } }} >{item?.invoiceType.charAt(0).toUpperCase() + item?.invoiceType.slice(1)}</td>
                        <td className="text-capitalize">
                          {item?.isRecivePaymentInvoice ? paymentMethodPosted(item) :"-"}
                        </td>
                      <td className='cursor-pointer' onClick={() => { if (item?.isRecivePaymentInvoice) {
                         sessionStorage.removeItem('need_review_filtersData')
                         sessionStorage.removeItem('posted_filtersData')
                         sessionStorage.removeItem('finalized_filtersData')
                          this.setState({
                            isCustomerModelOpen: true,
                            customerId: item?.customer?.customerId,
                          });
                          this.props.navigate(
                            `/finalized/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                          );
                        } else {
                          this.modelOpen(index)
                        } }} >{item?.total ? `$${ this.numberWithCommas(item?.total)}` : "-"}</td>
                      <td className='cursor-pointer' onClick={() => { if (item?.isRecivePaymentInvoice) {
                         sessionStorage.removeItem('need_review_filtersData')
                         sessionStorage.removeItem('posted_filtersData')
                         sessionStorage.removeItem('finalized_filtersData')
                          this.setState({
                            isCustomerModelOpen: true,
                            customerId: item?.customer?.customerId,
                          });
                          this.props.navigate(
                            `/finalized/customer-detail/${item?.customer?.customerId}/billing/payment-receipt`
                          );
                        } else {
                          this.modelOpen(index)
                        } }} >{item?.balacnce ? `$${ this.numberWithCommas(item?.balacnce)}` : "-"}</td>
                      {/* <td>{item?.invoiceDelivery}</td>
                    <td><DeliveryStatusIcon className="icon icon-green"/></td> */}
                          {/* <td><button className="btn btn-for-cc">Open</button> */}
                          <td>
                            {/* <button className={item?.balacnce === 0 ? "btn btn-for-code btn-green " : "btn btn-for-cc"}> {item?.balacnce === 0 ? "Paid" : "open"}</button> */}
                            {(item?.paymentStatus === "Open" ||
                              item?.paymentStatus === "open") && (
                              <button className="btn btn-for-cc">Open</button>
                            )}
                            {(item?.paymentStatus === "Overdue" ||
                              item?.paymentStatus === "overdue") && (
                              <button className="btn red-btn-status">
                                Over Due
                              </button>
                            )}
                            {(item?.paymentStatus === "Applied" ||
                              item?.paymentStatus === "applied") && (
                              <button className="btn gray-btn-status">
                                Applied
                              </button>
                            )}
                            {(item?.paymentStatus === "Paid" ||
                              item?.paymentStatus === "paid") && (
                              <button className="btn btn-for-code btn-green">
                                Paid
                              </button>
                            )}
                            {(item?.paymentStatus === "Voided" ||
                              item?.paymentStatus === "voided") && (
                              <button className="btn btn-for-code btn-voided">
                                Voided
                              </button>
                            )}
                          </td>
                          <td className="last-child- sticky-block">
                            <Dropdown
                              overlay={() => this.menu(index)}
                              trigger={["click"]}
                              overlayClassName="profile--dropdown--overlay dots-dropdown_link"
                            >
                              <button className="btn ant-dropdown-link pl-14 pr-6 three-dot">
                                <MoreIcon />
                              </button>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td colSpan={10}>
                            <div className="empty__section">
                              <div className="empty__section-inner">
                                <p className="empty__text">
                                  No Finalized Invoices
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    ):(
                      <>
                        <tr>
                          <td colSpan={12}>
                            <div className="empty__section">
                              <div className="empty__section-inner">
                                <p className="empty__text">
                                  <Spin spinning={this.state.isLoading} indicator={antIcon} />
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    )
                    // <NotFound title="No Finalized Invoices" />
                  }
                </tbody>
              </table>
             
              <Pagination
              className="text-center"
              onChange={this.onChangePage}
              hideOnSinglePage
              current={this.state.currentPage}
              total={this.state.totalItems}
              pageSize={this.state.limit}
              showSizeChanger={false}
            />
            </div>
            
          </div>
        </section>
        <Drawer
          title="Basic Drawer"
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          className="select__driver-drawer"
        >
          <div className="head-section-header d-flex align-items-center justify-content-between">
            <button
              className="btn btn-dark btn-sm clear-btn"
              onClick={() => {
                this.setState(
                  { filtersData: [], reRender: !this.state.reRender },
                  () => {
                    sessionStorage.removeItem('need_review_filtersData')
                    sessionStorage.removeItem('finalized_filtersData')
                    sessionStorage.removeItem('posted_filtersData')
                    this.fetchFinalizeInvoice(searchText, startDate, endDate);
                  }
                );
              }}
            >
              Clear
            </button>
            <div className="head-section-title">Filters</div>
            <div className="head-section-cta">
              <div onClick={this.onClose} className="btn btn-link btn-back">
                <CloseIcon />
              </div>
            </div>
          </div>
          <ul className="filter__list">
            <>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("open")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("open")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Open"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("paid")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("paid")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Paid"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("applied")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("applied")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Applied"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("overdue")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("overdue")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Over Due"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("voided")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("voided")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Voided"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("payment")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("payment")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Payment"}</p>
                  </label>
                </span>
                <div
                // className={`filter__list-item-inner ${
                //   this.state.dateFilter || (from && to) ? "show-list-detail" : ""
                // }`}
                ></div>
              </li>
              <li className="sub-manu">
                <ul className="sub-manu-list">
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                         onChange={(e) => {
                          this.openFilterList("cash");
                         }}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("cash")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Cash"}</p>
                      </label>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={(e) => this.openFilterList("check")}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("check")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Check"}</p>
                      </label>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={(e) => this.openFilterList("credit-card")}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("credit-card")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Credit Card"}</p>
                      </label>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={(e) => this.openFilterList("credit-memo")}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("credit-memo")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Credit Memo"}</p>
                      </label>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={(e) => this.openFilterList("terminal")}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("terminal")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Terminal"}</p>
                      </label>
                    </span>
                  </li>
                  <li>
                    <span className="d-flex -align-items-center filter__check">
                      <label className="custom-checkbox custom-checkbox-line">
                        <input
                          onChange={(e) => this.openFilterList("wire")}
                          name="dateFilter"
                          type="checkbox"
                          className="custom-checkbox--input"
                          checked={this.state.filtersData.includes("wire")}
                        />
                        <span className="checkmark"></span>
                        <p>{"Wire"}</p>
                      </label>
                    </span>
                  </li>
                </ul>
              </li>
              <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                    <input
                      onChange={(e) => this.openFilterList("nonSyncedToQb")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("nonSyncedToQb")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Awaiting Quickbooks Sync"}</p>
                  </label>
                </span>
                <div
                ></div>
              </li>
                  <li className="filter__list-item">
                <span className="d-flex -align-items-center filter__check">
                  <label className="custom-checkbox custom-checkbox-line">
                  <input
                      onChange={(e) => this.openFilterList("syncedToQb")}
                      name="dateFilter"
                      type="checkbox"
                      className="custom-checkbox--input"
                      checked={this.state.filtersData.includes("syncedToQb")}
                    />
                    <span className="checkmark"></span>
                    <p>{"Synced to Quickbooks"}</p>
                  </label>
                </span>
                <div
                ></div>
              </li>
            </>
          </ul>
        </Drawer>
        <div className="total-amount-list">
          <div className="total-amount-list-items">
            <h3 className="title">Total Invoices</h3> 
            : ${this.numberWithCommas(this.state?.invoicesTotal && this.state?.invoicesTotal > 0 ? this.state.invoicesTotal : 0)} 
          </div>
          <div className="total-amount-list-items">
            <h3 className="title">Total Payments</h3> 
            : ${this.numberWithCommas(this.state?.paymentsTotal && this.state?.paymentsTotal > 0 ? this.state.paymentsTotal : 0)}
          </div>
        </div>    
       
        {this.state.isModelOpen && (
          <InvoiceDetailComponent
            updateinvoiceDetailData={this.updateinvoiceDetailData}
            isModelOpen={this.state.isModelOpen}
            closeModel={this.closeModel}
            modelData={this.state.modelData}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            printByDropDown={this.state.isPrintRecipt}
            uploadImage={this.props.uploadImage}
            updateInvoicDetail={this.props.updateInvoicDetail}
            companyData={this.state.companyData}
            reArrangOrdersData={this.reArrangOrdersData}
            getOrderInvoicePdf={this.props.getOrderInvoicePdf}
            {...this.props}
          />
        )}
        {this.state.isPrintReciptt && (
          <div className="pdf-container" id="pdf-container">
            <InvoicePDF
              printRecipt={this.state.modelData}
              printReciptdatanew={this.state.modelData2}
              closePrintRecipt={() => {
                this.closePrintRecipt();
              }}
            />
          </div>
        )}
        {this.state.isPrintRecipttt && (
          <div className="pdf-container" id="pdf-container">
            <PrintInvoiceModal
              printRecipt={this.state.modelData}
              printReciptdatanew={this.state.modelData2}
              closePrintRecipt={() => {
                this.closePrintRecipt();
              }}
            />
          </div>
        )}
        {this.state.isCustomerModelOpen && (
          <CustomerDetailComponent
            syncPostedOrder={this.props.syncPostedOrder}
            customerId={this.state.customerId}
            getMe={this.props.getMe}
            fetchCustomer={this.props.fetchCustomer}
            changeUrl={this.assignUrl}
            closeModel={this.closeCustomerDetatilModel}
            updateDriver={this.props.updateDriver}
            updateTruck={this.props.updateTruck}
            deletePaymentMethod={this.props.deletePaymentMethod}
            defaultUrl={this.state.defaultUrl}
            getInvoiceDeliveryByIdData={this.props.getInvoiceDeliveryByIdData}
            getRecivingPaymentInvoices={this.props.getRecivingPaymentInvoices}
            sendInvoiceEmail={this.props.sendInvoiceEmail}
            printByDropDown={this.state.isPrintRecipt}
            uploadImage={this.props.uploadImage}
            updateInvoiceOrder={this.props.updateInvoiceOrder}
            updateInvoicDetail={this.props.updateInvoicDetail}
            getInvoiceDetalByInvoiceId={this.props.getInvoiceDetalByInvoiceId}
            getCustomercreditsForCustomers={
              this.props.getCustomercreditsForCustomers
            }
            getReceivePaymentForCustomers={
              this.props.getReceivePaymentForCustomers
            }
            customerInvoicesByCustomerId={
              this.props.customerInvoicesByCustomerId
            }
            {...this.props}
          />
        )}
      </div>
    );
  }
}
