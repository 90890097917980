import { connect } from 'react-redux'
import { getMe, fetchCompanyUsers ,updateUserToken,uploadImage,fetchMe} from '../../store/user/duck'
import { fetchCustomers ,fetchBillingCustomers, fetchCustomer,updateCustomer,updateDriver,updateTruck,deletePaymentMethod,fetchProjects,createProject,updateProject,updateTruckWithIndex,fetchLogs} from '../../store/customer/duck'
import { fetchCompanyDrivers,fetchSetting} from '../../store/company/duck'
import { fetchReports, exportOrder, generatePdf, generateMultiplePdf, getOrderInvoicePdf, fetchOrder,fetchProjectOrderList } from "../../store/order/duck";
import BillingComponent from './component'
import { fetchTransactions } from "../../store/transaction/duck";
import { fetchNeedReview, fetchPostedOrder, fetchPostedInvoicesCount, fetchFinalizedInvoice, updateInvoiceOrder, syncPostedOrder, sendInvoiceEmail, sendReminderInvoiceEmail, downloadBulkInvoice, sendInvoiceEmail2, syncBulkPostedOrder, getQbDetails, batchPrintInvoice,printPaymentReciept ,getProjectByProjectId} from '../../store/Billing/duck';
import { getPayabliTransactions , getPaybliIntegration } from '../../store/paybli_integrations/duck';
import {fetchInvoiceDeliveryData,getInvoiceDeliveryByIdData} from "../../store/invoiceDelivery/duck"
import {getPricingTierById} from "../../store/Pricing_tier/duck"    
import {getRecivingPaymentInvoices ,movedInvoiceBulkAction ,updateInvoicDetail,getInvoiceDetalByInvoiceId,getCustomercreditsForCustomers,getReceivePaymentForCustomers,getReceivePaymentForPdf,getCompanyById,customerInvoicesByCustomerId, customerOpenBalanceInvoicesByCustomerId, generateStatementPdf, statementEmail, setSearchText} from '../../store/Billing/duck'
import { getPayabliCustomerData } from '../../store/paybli_integrations/duck'; 
import { fetchContainers } from '../../store/container/duck';

const BillingContainer = connect(
  // Map state to props
  state => ({
    user: state.user.user,
    userPhase: state.user.userPhase,
    searchText: state.billing.searchText,
    searchStartsWith: state.billing.searchStartsWith
  }),
  {
    fetchOrder,
    getMe,
    fetchReports,
    fetchCustomers,
    fetchMe,
    fetchCompanyUsers,
    fetchCompanyDrivers,
    fetchLogs,
    updateUserToken,
    getProjectByProjectId,
    exportOrder,
    generatePdf,
    generateMultiplePdf,
    fetchCustomer,
    updateCustomer,
    updateDriver,
    updateTruck,
    deletePaymentMethod,
    fetchTransactions,
    fetchNeedReview,
    getPayabliTransactions,
    fetchPostedOrder,
    fetchPostedInvoicesCount,
    fetchInvoiceDeliveryData,
    getInvoiceDeliveryByIdData,
    getPricingTierById,
    syncPostedOrder,
    updateInvoiceOrder,
    uploadImage,
    sendInvoiceEmail,
    fetchFinalizedInvoice,
    sendReminderInvoiceEmail,
    getRecivingPaymentInvoices,
    updateInvoicDetail,
    downloadBulkInvoice,
    getInvoiceDetalByInvoiceId,
    getCustomercreditsForCustomers,
    getPaybliIntegration,
    getReceivePaymentForCustomers,
    getReceivePaymentForPdf,
    sendInvoiceEmail2,
    customerInvoicesByCustomerId,
    customerOpenBalanceInvoicesByCustomerId,
    getOrderInvoicePdf,
    syncBulkPostedOrder,
    getQbDetails,
    getCompanyById,
    generateStatementPdf,
    statementEmail,
    getPayabliCustomerData,
    movedInvoiceBulkAction,
    fetchBillingCustomers,
    fetchContainers,
    fetchProjects,
    createProject,
    updateProject,
    fetchProjectOrderList,
    fetchSetting,
    updateTruckWithIndex,
    printPaymentReciept,
    batchPrintInvoice,
    setSearchText

  }
)(BillingComponent);

export default BillingContainer
