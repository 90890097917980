import { connect } from 'react-redux'
import { getMe,fetchMe,updateUserToken,uploadImage } from '../../store/user/duck'
import { fetchCustomer, updateDriver, updateTruck, deletePaymentMethod,fetchProjects,createProject,updateProject,updateTruckWithIndex ,fetchLogs} from '../../store/customer/duck'
import {getInvoiceDeliveryByIdData} from "../../store/invoiceDelivery/duck"
import CustomerDetailComponent from './component'
import {sendInvoiceEmail,updateInvoiceOrder,updateInvoicDetail,getInvoiceDetalByInvoiceId,getRecivingPaymentInvoices,getReceivePaymentForCustomers,syncPostedOrder,customerInvoicesByCustomerId, getQbDetails,customerOpenBalanceInvoicesByCustomerId, getReceivePaymentForPdf ,getProjectByProjectId } from '../../store/Billing/duck'
import { getPayabliCustomerData } from '../../store/paybli_integrations/duck';
import { getOrderInvoicePdf, fetchOrder,fetchProjectOrderList } from "../../store/order/duck";
import { fetchContainers } from '../../store/container/duck';
import { fetchSetting} from '../../store/company/duck';

const CustomerDetailContainer = connect(
  // Map state to props
  state => ({
  	user: state.user.user,
  	userPhase: state.user.userPhase
  }),
  {
    getQbDetails,
    fetchOrder,
    syncPostedOrder,
    getMe,
    fetchMe,
    updateUserToken,
    getProjectByProjectId,
    fetchCustomer,
    updateDriver,
    updateTruck,
    updateTruckWithIndex,
    deletePaymentMethod,
    getPayabliCustomerData,
    getInvoiceDeliveryByIdData,
    getRecivingPaymentInvoices,
    sendInvoiceEmail,
    uploadImage,
    updateInvoiceOrder,
    updateInvoicDetail,
    getInvoiceDetalByInvoiceId,
    getReceivePaymentForCustomers,
    customerInvoicesByCustomerId,
    getOrderInvoicePdf,
    customerOpenBalanceInvoicesByCustomerId,
    getReceivePaymentForPdf,
    fetchContainers,
    fetchProjects,
    createProject,
    fetchProjectOrderList,
    updateProject,
    fetchSetting,
    fetchLogs
  }
)(CustomerDetailComponent)

export default CustomerDetailContainer
