import { fetch } from "../../utils";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME;

export const fetchOrders = ({ startDate, endDate, skip, limit }) => {
  return fetch(`${HOSTNAME}/orders?startDate=${startDate}&endDate=${endDate}&skip=${skip}&limit=${limit}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchOrder = id => {
  return fetch(`${HOSTNAME}/order/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const updateOrder = ({ id, ...rest }) => {
  return fetch(`${HOSTNAME}/order/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(rest)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const updateOrderData = (payload) => {
  return fetch(`${HOSTNAME}/put_order`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchOrderList = ({ ...filter }) => {
  return fetch(`${HOSTNAME}/orderlist?filter=${JSON.stringify(filter)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchProjectOrderList = ({...filter }) => {
  return fetch(`${HOSTNAME}/Projectorderlist?filter=${JSON.stringify(filter)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
export const fetchReports = ({ from, to, ...filter }) => {
  return fetch(`${HOSTNAME}/fetch-reports?filter=${JSON.stringify(filter)}&from=${from}&to=${to}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};


export const updateOrderSustainabilityInfo = data => {
  return fetch(`${HOSTNAME}/sustainability-info/${data.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ info: data.sustainabilityInfo })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const exportOrder = ({ ...filter }) => {
  return fetch(`${HOSTNAME}/export-orders?filter=${JSON.stringify(filter)}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const sendEmailReceipt = ({ ticketNumber, timezone }) => {
  return fetch(`${HOSTNAME}/email-receipt/${ticketNumber}?timezone=${timezone}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const deleteOrder = (id,reason) => {
  return fetch(`${HOSTNAME}/order/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ reason })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const generatePdf = ({ ticketNumber, timezone }) => {
  return fetch(`${HOSTNAME}/generate-pdf/${ticketNumber}?timezone=${timezone}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const generateMultiplePdf = ({ ticketNumbers, timezone }) => {
  return fetch(`${HOSTNAME}/create-zip`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ ticketNumbers, timezone })
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const updateTotalAmount = ({ id, ...data }) => {
  return fetch(`${HOSTNAME}/total-amount/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const fetchDevices = () => {
  return fetch(`${HOSTNAME}/devices`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const chargeOnDevice = payload => {
  return fetch(`${HOSTNAME}/charge-device`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      return { status: false, errMessage: error?.message}
      // throw error;
    });
};
export const printPerforatedOrder = payload => {
  return fetch(`${HOSTNAME}/print-perforated-order`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.blob();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
export const printOrderDetails = payload => {
  return fetch(`${HOSTNAME}/print-order-details`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      return res.blob();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};

export const getOrderInvoicePdf = id => {
  return fetch(`${HOSTNAME}/ticket-order-invoice/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => {
      return res.blob();
    })
    .then(payload => {
      return payload;
    })
    .catch(error => {
      throw error;
    });
};
